import { Icon } from '@chakra-ui/icons';
import React from 'react';

interface ProperRightArrowProps {
  fillColor: string;
}

export default function CustomRightArrow(props: ProperRightArrowProps) {
  const { fillColor } = props;
  return (
    <Icon width="15px" height="15px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_1561_137" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect x="0.666504" y="0.460938" width="14" height="14" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_1561_137)">
        <path
          d="M10.0875 8.04424H3.58333C3.41806 8.04424 3.27951 7.98833 3.16771 7.87653C3.0559 7.76472 3 7.62618 3 7.4609C3 7.29563 3.0559 7.15708 3.16771 7.04528C3.27951 6.93347 3.41806 6.87757 3.58333 6.87757H10.0875L8.425 5.21507C8.30833 5.0984 8.25243 4.96229 8.25729 4.80674C8.26215 4.65118 8.31806 4.51507 8.425 4.3984C8.54167 4.28174 8.68021 4.22097 8.84063 4.21611C9.00104 4.21125 9.13958 4.26715 9.25625 4.38382L11.925 7.05257C11.9833 7.1109 12.0247 7.1741 12.049 7.24215C12.0733 7.31021 12.0854 7.38313 12.0854 7.4609C12.0854 7.53868 12.0733 7.6116 12.049 7.67965C12.0247 7.74771 11.9833 7.8109 11.925 7.86924L9.25625 10.538C9.13958 10.6547 9.00104 10.7106 8.84063 10.7057C8.68021 10.7008 8.54167 10.6401 8.425 10.5234C8.31806 10.4067 8.26215 10.2706 8.25729 10.1151C8.25243 9.95951 8.30833 9.8234 8.425 9.70674L10.0875 8.04424Z"
          fill={fillColor}
        />
      </g>
    </Icon>
  );
}
