import {
  AddNftToCompetitionPayload,
  AddNftToCompetitionResponse,
  CompetitionResponse,
  MyCompetitionResponse,
  VoteToCompetition,
  VoteToCompetitionResponse,
} from '../../interfaces/components';
import axios from '../axios';

export const getAllCompetitions = async (page: number, recordsPerPage: number): Promise<CompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMyCompetitions = async (page: number, recordsPerPage: number): Promise<MyCompetitionResponse> => {
  try {
    const endPoint = `/api/user/my-competitions/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompetitionDetails = async (id: number): Promise<any> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}`;
    const res = await axios.get(endPoint);
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addNftToCompetition = async (
  id: number,
  data: AddNftToCompetitionPayload,
): Promise<AddNftToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competitions/${id}/add-nft/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding nft to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addVoteToCompetingNfts = async (
  id: number,
  data: VoteToCompetition,
): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competition/${id}/vote/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding nft to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const removeVoteFromCompetingNfts = async (
  id: number,
  data: VoteToCompetition,
): Promise<VoteToCompetitionResponse> => {
  try {
    const endPoint = `/api/nfts/competition/${id}/unvote/`;
    const res = await axios.post(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw 'Something went wrong while adding nft to competition';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getLeaderBoardData = async (competitionId: number) => {
  try {
    const endPoint = `/api/nfts/competition/leaderboard/`;
    const res = await axios.get(endPoint, {
      params: {
        competition_id: competitionId,
      },
    });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
