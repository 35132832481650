import { Box, Text, Image, Progress, useToast } from '@chakra-ui/react';
import React from 'react';
import CustomRightArrow from '../../Icons/CustomArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserLevelResponse } from '../../interfaces/components';
import storage from '../../utils/storage';

interface RankProps {
  isProfilePage?: boolean;
  userLevelData?: UserLevelResponse | null;
}

export default function Rank(props: RankProps) {
  const { isProfilePage, userLevelData } = props;
  const walletAddress = storage.get('walletAddress');
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const onProgressClick = () => {
    if (walletAddress) {
      navigate('/my-progress');
    } else {
      toast({
        title: 'Connect you Wallet',
        description: 'Connect your wallet first to view your progress',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      borderRadius="26px"
      backgroundColor=" rgba(255, 255, 255, 0.10)"
      width={['100%', '100%', isProfilePage ? '100%' : '48.25%', '49%', '32.25%']}
      padding="24px"
      border="var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)"
      background=" #242424"
    >
      <Box display="flex" gap="16px">
        <Box width="60px" height="68px">
          <Image src={userLevelData?.level_image} />
        </Box>
        <Box display="flex" justifyContent="space-around" alignItems="flex-start" flexDirection="column" gap="2px">
          <Box borderRadius="19px" background=" rgba(255, 255, 255, 0.10)" padding="4px 10px">
            <Text
              color="#FFF"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Rank
            </Text>
          </Box>
          <Text
            color="#FFF"
            textAlign="center"
            fontFamily="Montserrat"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="normal"
          >
            {userLevelData?.level_name}
          </Text>
        </Box>
      </Box>
      <Box height="21px" marginTop="24px" display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Text
            color="#19A0ED"
            fontFamily="Montserrat"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="normal"
            marginBottom="24px"
          >
            {userLevelData?.current_points ? userLevelData?.current_points : userLevelData?.starting_points}/
            <span
              style={{
                color: '#8C8C8C',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
              }}
            >
              {userLevelData?.ending_points}XP
            </span>
          </Text>
        </Box>

        <Box display="flex" alignItems="center">
          <CustomRightArrow fillColor={'#D9D9D9'} />
          <Text color="#8C8C8C" fontSize=" 14px" fontStyle=" normal" fontWeight=" 600" lineHeight="normal">
            {userLevelData?.next_level_name}
          </Text>
        </Box>
      </Box>
      <Box marginTop="8px">
        <Progress borderRadius="26px" value={userLevelData?.percentage_completion} height="16px" />
      </Box>
      {location?.pathname === '/my-profile' && (
        <Box display="flex" justifyContent="flex-end" marginTop="16px">
          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            justifyContent="center"
            cursor="pointer"
            onClick={onProgressClick}
          >
            <Text
              color="#19A0ED"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Your Progress
            </Text>
            <CustomRightArrow fillColor={'#19A0ED'} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
