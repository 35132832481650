import React, { useEffect, useState } from 'react';
import { Box, Text, Divider, useDisclosure, Button } from '@chakra-ui/react';
import GroupIcon from '../../Icons/GroupIcon';
import VotesIcon from '../../Icons/Votes';
import { SubmitNftModal } from '../modals/SubmitNft';
import { AppStoreState, useAppStore } from '../../store';
import AddToNftSuccessIcon from '../../Icons/AddToNftSuccess';
import AddToNftFailureIcon from '../../Icons/AddToNftFailure';
import FeatureCardIcon from '../../Icons/FeatureCard';
import Clock from '../../Icons/Clock';
import Countdown from '../common/Countdown';
import { CompetitionCommentsDrawer } from '../modals/CompetitionCommentsDrawer';
import { getCompetitionComments } from '../../api/competitionComments/api';
import { BellIcon } from '@chakra-ui/icons';
// import { parseISO, isBefore } from 'date-fns';
import { CompetitionObject } from '../../interfaces/components';
// import CommentIcon from '../../Icons/CommentIcon';
import { isAfter, parseISO, isBefore } from 'date-fns';
import CommentIcon from '../../Icons/CommentIcon';

interface CompetitionStatsProps {
  competitionDetails: CompetitionObject;
  bgColorMainBox: string;
  playerCount: number;
  countDownDate: any;
  startDate: string;
  totalVotes: number;
  onVoteNowClick: () => void;
}

export default function CompetitionStats(props: CompetitionStatsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCommentsModalOPen, onOpen: onCommentsModalOpen, onClose: onCommentsModalClose } = useDisclosure();
  const { bgColorMainBox, playerCount, countDownDate, startDate, totalVotes, competitionDetails, onVoteNowClick } =
    props;
  const [submitClicked, setSubmitClicked] = useState(false);
  const [reRender, setRerender] = useState(false);
  const numberOfComments = useAppStore((state: AppStoreState) => state.numberOfComments);
  const setNumberOfComments = useAppStore((state: AppStoreState) => state.setNumberOfComments);
  const addNftToCompetitionStatus = useAppStore((state: AppStoreState) => state.addNftToCompetitionStatus);
  const setMintNowView = useAppStore((state: AppStoreState) => state.setMintNowView);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);

  console.log('competitionDetails', competitionDetails);

  const onParticipateClick = () => {
    setSelectedNft(null);
    setMintNowView(false);
    onOpen();
  };

  const onSaveClick = () => {
    onCommentsModalOpen();
  };

  useEffect(() => {
    selectedCompetitionId && fetchComments(selectedCompetitionId);
  }, []);

  useEffect(() => {
    setRerender(!reRender);
  }, [numberOfComments]);

  const fetchComments = async (id: number) => {
    try {
      const res = await getCompetitionComments(id);
      setNumberOfComments(res?.length);
    } catch (error: unknown) {}
  };

  console.log('onsvae', onSaveClick, onParticipateClick, accessToken, startDate);

  return (
    <>
      <CompetitionCommentsDrawer
        isOpen={isCommentsModalOPen}
        onOpen={onCommentsModalOpen}
        onClose={onCommentsModalClose}
      />
      {submitClicked && (
        <SubmitNftModal
          heading={addNftToCompetitionStatus === 'success' ? 'Submitted successfully' : 'Submission failed'}
          subHeading={
            addNftToCompetitionStatus === 'success'
              ? 'Your NFT has been submitted into the competition.'
              : 'Your NFT has been created, but failed to submit, please try to resubmit it later.'
          }
          buttonText={'Close'}
          buttonBgColor={addNftToCompetitionStatus === 'success' ? '#118BCF' : '#E13838'}
          isModalOpen={submitClicked}
          onModalOpen={onOpen}
          onModalClose={onClose}
          setSubmitClicked={setSubmitClicked}
          modalIcon={addNftToCompetitionStatus === 'success' ? <AddToNftSuccessIcon /> : <AddToNftFailureIcon />}
        />
      )}
      <SubmitNftModal
        isModalOpen={isOpen}
        onModalOpen={onOpen}
        onModalClose={onClose}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
        heading={'Submit your NFT'}
        subHeading={' Browse your NFTs or mint a new one to compete in this competition.'}
        buttonText={'Browse NFTs'}
        buttonBgColor={'#222'}
        modalIcon={<FeatureCardIcon />}
      />
      <Box
        width={['auto', 'auto', 'auto', '500px', '590px']}
        marginTop={['unset', 'unset', 'unset', '24px']}
        borderRadius="24px"
        background={bgColorMainBox}
        padding="10px"
      >
        <Box
          borderRadius="15px"
          background="rgba(0, 0, 0, 0.20)"
          padding="10px"
          display="flex"
          flexDirection={['column', 'row', 'row']}
        >
          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <GroupIcon />
              <Text
                color="#96BCAD"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Entries
              </Text>
            </Box>
            <Box>
              <Text
                color=" #FFF"
                textAlign="center"
                fontFamily=" Montserrat"
                fontSize="20px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {playerCount ? playerCount : 0}
              </Text>
            </Box>
          </Box>
          <Box display={['none', 'block', 'block']}>
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" height="56px" />
          </Box>
          <Box display={['block', 'none', 'none']} marginTop="10px" marginBottom="10px">
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="horizontal" />
          </Box>

          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <VotesIcon color="#96BCAD" />
              <Text
                color="#96BCAD"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Total Votes
              </Text>
            </Box>
            <Box>
              <Text
                color=" #FFF"
                textAlign="center"
                fontFamily=" Montserrat"
                fontSize="20px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {totalVotes}
              </Text>
            </Box>
          </Box>

          <Box display={['none', 'block', 'block']}>
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" height="56px" />
          </Box>
          <Box display={['block', 'none', 'none']} marginTop="10px" marginBottom="10px">
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="horizontal" />
          </Box>
          <Box
            width={['unset', '33%', '33%']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
              <Clock color="#96BCAD" w="24px" h="24px" />
              <Text
                color="#96BCAD"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Time Left
              </Text>
            </Box>
            <Box>
              <Countdown endDateString={countDownDate} color=" #FFF" fontSize="13px" fontWeight={600} />
            </Box>
          </Box>
        </Box>

        {!accessToken ? (
          <Box marginTop="10px">
            <Button
              width="100%"
              borderRadius="15px"
              border=" 0.5px solid rgba(255, 255, 255, 0.20)"
              padding="16px 32px"
              leftIcon={<CommentIcon />}
              color=" #FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              h={['52px', '52px', '56px']}
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comment ({numberOfComments})
            </Button>
          </Box>
        ) : isAfter(parseISO(competitionDetails?.participation_starts), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comment ({numberOfComments})
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color="#FFF"
              leftIcon={<BellIcon />}
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
            >
              Get notified
            </Button>
          </Box>
        ) : isAfter(parseISO(competitionDetails?.voting_starts), competitionDetails?.participation_starts) &&
          isAfter(parseISO(competitionDetails?.voting_starts), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comment ({numberOfComments})
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color=" #FFF"
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => onParticipateClick()}
            >
              Participate
            </Button>
          </Box>
        ) : isBefore(parseISO(competitionDetails?.participation_starts), competitionDetails?.voting_starts) &&
          isAfter(parseISO(competitionDetails?.voting_ends), new Date()) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="10px">
            <Button
              width="50%"
              borderRadius="15px"
              border="0.5px solid rgba(255, 255, 255, 0.20)"
              padding="12px 24px"
              color="#FFF"
              backgroundColor="#222"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: 'rgba(255, 255, 255, 0.20)',
              }}
              onClick={onSaveClick}
            >
              Comment ({numberOfComments})
            </Button>
            <Button
              width="50%"
              borderRadius="15px"
              padding="12px 24px"
              color=" #FFF"
              backgroundColor="#118BCF"
              fontSize={['16px', '16px', '20px']}
              fontWeight="600"
              height="48px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={onVoteNowClick}
            >
              Vote
            </Button>
          </Box>
        ) : (
          isBefore(parseISO(competitionDetails?.voting_ends), new Date()) && (
            <Box marginTop="10px">
              <Button
                width="100%"
                borderRadius="15px"
                border="0.5px solid rgba(255, 255, 255, 0.20)"
                padding="12px 24px"
                color="#FFF"
                backgroundColor="#222"
                fontSize={['16px', '16px', '20px']}
                fontWeight="600"
                height="48px"
                _hover={{
                  color: 'white',
                  bg: 'rgba(255, 255, 255, 0.20)',
                }}
                onClick={onSaveClick}
              >
                Comment ({numberOfComments})
              </Button>
            </Box>
          )
        )}
      </Box>
    </>
  );
}
