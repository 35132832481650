import { Box, Text, Image, Divider, Button, useToast, Spinner } from '@chakra-ui/react';
import EthereumLogo from '../../Icons/Ethereum';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllNftsResponse, AllNftsResponseData } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { getAllNfts } from '../../api/nfts/apis';
import VerifiedLogo from '../../Icons/Verified';

interface FeaturedNftProps {
  isNftDetailPage?: boolean;
  fetchAgain?: boolean;
  setFetchAgain?: (fetch: boolean) => void;
}

export default function FeaturedNft(props: FeaturedNftProps) {
  const setRerender = useAppStore((state: AppStoreState) => state.setRerender);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const { isNftDetailPage, fetchAgain, setFetchAgain } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const [allNfts, setAllNfts] = useState<AllNftsResponse>();

  const fetchData = async (page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = await getAllNfts(page, recordsPerPage);
      setAllNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchData(1, 10);
  }, []);

  const onNftClick = (nft: AllNftsResponseData) => {
    setRerender(!reRender);
    if (setFetchAgain) {
      setFetchAgain(!fetchAgain);
    }
    navigate(`/nfts/${nft?.identifier}`);
  };

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box paddingTop="12px 0px 64px 0px">
          <Text color=" #FFF" fontSize={['28px', '28px', '36px']} fontWeight="800" marginBottom="24px">
            {isNftDetailPage ? 'Similar NFTs' : 'Featured NFTs'}
          </Text>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={['space-between', 'space-between', 'space-between', 'space-between', 'space-between']}
            rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
            columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
          >
            {allNfts &&
              allNfts?.data?.length > 0 &&
              allNfts?.data
                ?.filter((nft) => nft.display_image_url)
                .slice(0, 3)
                .map(
                  (nft, i) =>
                    nft?.display_image_url && (
                      <Box
                        key={i}
                        borderRadius="26px"
                        border="1px solid rgba(255, 255, 255, 0.10)"
                        width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                        onClick={() => onNftClick(nft)}
                        position="relative"
                        padding="16px 16px 24px 16px"
                        cursor="pointer"
                        backgroundColor={isNftDetailPage ? 'rgba(255, 255, 255, 0.10)' : ' rgba(255, 255, 255, 0.10)'}
                      >
                        <Image
                          width={['inherit', 'inherit', '100%', '100%', '100%']}
                          cursor="pointer"
                          src={nft?.display_image_url}
                          maxHeight="214px"
                          height="214px"
                          objectFit="cover"
                          borderRadius="12px"
                        />
                        <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                          {nft?.name}
                        </Text>
                        <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop="10px"
                          marginBottom="8px"
                        >
                          <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                            Creator
                          </Text>
                          {nft?.owner?.username ? (
                            <Box display="flex" justifyContent="center" gap="6px">
                              <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                                {nft?.owner?.username}
                              </Text>
                              <VerifiedLogo />
                            </Box>
                          ) : (
                            <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                              Anonymous
                            </Text>
                          )}
                        </Box>

                        <Box display="flex" flexDirection="column" gap="5px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                              Network
                            </Text>
                            <Box
                              borderRadius="19px"
                              background="rgba(255, 255, 255, 0.10)"
                              padding="4px 4px 4px 10px"
                              display="flex"
                              gap="6px"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                                Ethereum
                              </Text>
                              <EthereumLogo boxSize={6} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ),
                )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" marginTop="56px">
            <Button
              width="232px"
              borderRadius="15px"
              borderTop="1px solid #43BDD7"
              backgroundColor="#118BCF"
              padding="16px 32px"
              color=" #FFF"
              fontSize=" 20px"
              fontWeight=" 600"
              h="56px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => navigate('/nfts')}
            >
              See more
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
