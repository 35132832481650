import axiosClient, { AxiosInstance } from 'axios';
import storage from '../utils/storage';

const TIMEOUT = 100000; //15sec
const SERVER_URL = process.env.REACT_APP_API_HOST || 'https://api.layerx.baboons.tech';
// 'https://api.thegame.baboons.tech';

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_HOST || '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc',
  },
  timeout: TIMEOUT,
});

axios.interceptors.request.use(
  (config) => {
    const token = storage.get('accessToken');
    if (token && config?.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    // const originalConfig = err.config;
    // if (err.response && loginBaseUrl.includes(originalConfig.url)) {
    //     if (err.response.status === 401 && !originalConfig._retry) {
    //         originalConfig._retry = true;
    //         store.dispatch(reset)
    //     }
    // }
    return Promise.reject(err);
  },
);

export default axios;
