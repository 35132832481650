import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import NftListView from '../allNfts/NftListView';

interface CompetingNftsProps {
  nfts: any;
}

export default function CompetingNFTs(props: CompetingNftsProps) {
  const { nfts } = props;

  return (
    <>
      {false ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box marginTop={['56px', '56px', 'unset']}>
          <Text
            color="#FFF"
            fontFamily="Montserrat"
            fontSize=" 36px"
            fontStyle="normal"
            fontWeight="800"
            lineHeight="normal"
          >
            Competing NFTs
          </Text>
          <Box marginTop="24px">
            <NftListView allNfts={nfts} showButton={true} />
          </Box>
        </Box>
      )}
    </>
  );
}
