import { Box, Button, Divider, Image, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { AppMenuItem, NavbarProps } from '../../interfaces/components';
import { useAccount } from 'wagmi';
import { useUser } from '../../hooks';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { AppStoreState, useAppStore } from '../../store';
import ProfileIcon from '../../Icons/profile';
import ArrowUpIconBlue from '../../Icons/ArrowUpIcon';
import LogoutLogo from '../../Icons/LogoutLogo';
import storage from '../../utils/storage';
import { formatAddress } from '../../utils/commonFunctions';
import PowerSettingIcon from '../../Icons/PoweSettingIcon';
import ArrowDownIconGrey from '../../Icons/ArrowDownIcon';
import MobileMenuIcon from '../../Icons/MobileMenuIcon';

export const menuItems: AppMenuItem[] = [
  { text: 'Home', path: '/', id: 1 },
  { text: 'NFTs', path: '/nfts', id: 2 },
  { text: 'Competitions', path: '/competitions', id: 3 },
  { text: 'My profile', path: '/my-profile', id: 4 },
];

export default function Header({ openDrawer }: NavbarProps) {
  const onMenuItemClick = (menuItem: AppMenuItem) => {
    navigate(menuItem?.path);
  };
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const walletAddress = storage.get('walletAddress');
  const userName = storage.get('userName');

  const { address } = useAccount();
  const { isLoading, logout } = useUser();
  const navigate = useNavigate();

  const { open } = useWeb3Modal();

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  useEffect(() => {
    if (!isLoading && address) {
    }
  }, [isLoading, address]);

  const onLogoutClick = async () => {
    setLoading(true);
    await logout();
    setLoading(false);
  };

  const onConnectButtonClick = async () => {
    if (!address) {
      handleLogin();
    } else {
      console.log('from else');
    }
  };

  const isActiveMenuItem = (menuPath: string, currentPath: string) => {
    if (menuPath === '/') {
      return currentPath === '/';
    }
    return currentPath.startsWith(menuPath);
  };

  return (
    <>
      <Box width="100%" h={['54px', '54px', '54px', '65px', '65px']} borderBottom="1px solid rgba(255, 255, 255, 0.10)">
        <Box
          maxWidth={['unset', 'unset', '1400px']}
          margin="0 auto"
          width="100%"
          h={['54px', '54px', '54px', '65px', '65px']}
          marginBottom="30px"
          position="relative"
        >
          <Box
            margin="0 auto"
            width="inherit"
            height="inherit"
            padding={['14px 12px', '14px 12px', '14px 12px', '20px 24px']}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              cursor="pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <Image src={Logo} height={['24px', '24px', '24px', '24x', '24px']} />
            </Box>

            <Box
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              display={['none', 'none', 'flex']}
              gap="24px"
            >
              {menuItems.map((menu, i) => (
                <Box key={i} onClick={() => onMenuItemClick(menu)}>
                  <Text
                    color={isActiveMenuItem(menu.path, location.pathname) ? '#FFF' : 'rgba(255, 255, 255, 0.50)'}
                    fontSize="14px"
                    fontWeight="600"
                    cursor="pointer"
                  >
                    {menu?.text}
                  </Text>
                </Box>
              ))}
            </Box>

            <Box
              padding="1px"
              borderRadius="24px"
              borderWidth="none !important"
              style={{
                borderRadius: '24px',
                backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
              }}
            >
              <Box
                display={['none', 'none', 'flex']}
                gap="10px"
                width="210px"
                height="44px"
                padding="10px 15px 10px 10px"
                borderRadius="24px"
                backgroundColor="#1F1F1F"
              >
                <ProfileIcon />
                {userName && (
                  <Text color=" rgba(255, 255, 255, 0.50)" fontSize=" 15px" fontWeight="500" textTransform="capitalize">
                    {userName}
                  </Text>
                )}

                <Divider
                  border="1px solid rgba(255, 255, 255, 0.10) !important"
                  marginBottom="10px"
                  orientation="vertical"
                />
                <Menu placement="bottom-end">
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        display="flex"
                        width=" unset"
                        backgroundColor=" transparent"
                        height=" unset"
                        justifyContent="flex-end"
                        as={Button}
                        rightIcon={isOpen ? <ArrowUpIconBlue /> : <ArrowDownIconGrey />}
                        _hover={{
                          color: 'white',
                          bg: 'transparent',
                        }}
                        _active={{
                          color: 'white',
                          bg: 'transparent',
                        }}
                        minWidth="10px"
                      ></MenuButton>

                      <MenuList
                        marginTop="20px"
                        padding="1px"
                        borderRadius="24px"
                        backgroundColor="#1F1F1F"
                        boxShadow="-5px 14px 34px 0px rgba(0, 0, 0, 0.35)"
                        borderWidth="none !important"
                        zIndex={2}
                        style={{
                          borderRadius: '24px',
                          backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
                          backgroundOrigin: 'border-box',
                          backgroundClip: 'content-box, border-box',
                        }}
                      >
                        <Box padding="14px" borderRadius=" 24px">
                          {address ? (
                            <Box
                              padding="9px"
                              borderRadius="14px"
                              background="#000"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text color="rgba(255, 255, 255, 0.50)" fontSize="16px" fontWeight="500">
                                {address && formatAddress(address, 5, 4)}
                              </Text>
                              <PowerSettingIcon />
                            </Box>
                          ) : (
                            <Box
                              display={walletAddress ? 'block' : 'none'}
                              padding="9px"
                              borderRadius="14px"
                              background="#000"
                            >
                              <Text color=" rgba(255, 255, 255, 0.50)" fontSize=" 12px" fontWeight="500">
                                Linked wallet
                              </Text>
                              <Text color="#fff" fontSize="16px" fontWeight="600">
                                {walletAddress && formatAddress(walletAddress, 9, 4)}
                              </Text>
                            </Box>
                          )}
                          {!address && (
                            <Box marginTop="8px" marginBottom="24px">
                              <Button
                                width="100%"
                                borderRadius="15px"
                                backgroundColor="#118BCF"
                                padding="16px 32px"
                                color=" #FFF"
                                fontSize="16px"
                                fontWeight=" 600"
                                h="44px"
                                _hover={{
                                  color: 'white',
                                  bg: '#43BDD7',
                                }}
                                onClick={onConnectButtonClick}
                              >
                                Connect Wallet
                              </Button>
                            </Box>
                          )}
                          <Divider
                            border="1px solid rgba(255, 255, 255, 0.10) !important"
                            marginTop="8px"
                            marginBottom="10px"
                          />
                          <Box>
                            <Button
                              padding="16px 32px"
                              color="rgba(255, 255, 255, 0.50)"
                              fontSize="15px"
                              fontWeight="500"
                              h="44px"
                              zIndex={99}
                              _hover={{
                                color: 'white',
                                bg: 'rgba(255, 255, 255, 0.50)',
                              }}
                              onClick={onLogoutClick}
                              width="100%"
                              leftIcon={<LogoutLogo />}
                              borderRadius="15px"
                              border="0.5px solid rgba(255, 255, 255, 0.10)"
                              backgroundColor="rgba(255, 255, 255, 0.05)"
                              cursor="pointer"
                            >
                              Logout
                            </Button>
                          </Box>
                        </Box>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </Box>
            <Box onClick={openDrawer} display={['unset', 'unset', 'none', 'none', 'none']}>
              <MobileMenuIcon
                // boxSize={8}
                cursor="pointer"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
