import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Image,
  useToast,
  Menu,
  MenuList,
  MenuButton,
  Button,
  Input,
  Spinner,
} from '@chakra-ui/react';
import SendMessageImage from '../../assets/sendMessage.svg';
import SendMessageActive from '../../assets/addCommentActive.svg';
import ProfilePicture from '../../assets/profilePicture.png';
import React, { useEffect, useState } from 'react';
import Trophy from '../../assets/trophy.png';
import CloseIcon from '../../Icons/Close';
import { AppStoreState, useAppStore } from '../../store';
import { CommentsResponse } from '../../interfaces/components';
import TimeAgo from '../Timer';
import CommentDropDown from '../../Icons/CommentDropDown';
import {
  addCompetitionComments,
  deleteCompetitionComment,
  getCompetitionComments,
  updateCompetitionComment,
} from '../../api/competitionComments/api';
import storage from '../../utils/storage';
import { useNavigate } from 'react-router-dom';
import CloseIconGray from '../../Icons/CloseIconGrey';

interface LeaderBoardDrawerProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const CompetitionCommentsDrawer = (props: LeaderBoardDrawerProps) => {
  const { isOpen, onClose, onOpen } = props;
  const toast = useToast();
  const navigate = useNavigate();
  const userName = storage.get('userName');
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const [loading, setLoading] = useState(false);
  const [editCommentId, setEditCommentId] = useState<any>(null);
  const [allComments, setAllComments] = useState<CommentsResponse[]>();
  const [editText, setEditText] = useState('');
  const [commentToDeleteId, setCommentToDeleteId] = useState<number | null>(null);
  const [value, setValue] = React.useState('');
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const [actionInProgress, setActionInProgress] = useState(false);
  const setNumberOfComments = useAppStore((state: AppStoreState) => state.setNumberOfComments);
  const user = storage.get('user');
  const currentUser = JSON.parse(user);

  useEffect(() => {
    selectedCompetitionId && fetchComments(selectedCompetitionId);
  }, []);

  const fetchComments = async (id: number) => {
    try {
      setActionInProgress(true); // Indicate that an action is in progress
      setLoading(true);
      const res = await getCompetitionComments(id);
      setAllComments(res);
      setNumberOfComments(res?.length);
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while fetching comments.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setActionInProgress(false); // Reset action in progress state
    }
  };

  const handleEditClick = (comment: CommentsResponse) => {
    setEditCommentId(comment.id);
    setEditText(comment.comment);
  };

  const removeCommentToEditFromState = () => {
    setEditCommentId('');
    setEditText('');
  };

  const handleCancelClick = () => {
    setEditCommentId(null);
    setEditText('');
  };

  const handleUpdateClick = (id: number) => {
    onUpdateComment(id, editText);
    setEditCommentId(null);
  };

  const handleInputChange = (e: { target: { value: any } }) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  useEffect(() => {
    if (actionInProgress) {
      isOpen && onOpen(); // Make sure the drawer stays open when action is in progress
    }
  }, [actionInProgress, onOpen]);

  const onUpdateComment = async (id: number, editText: string) => {
    try {
      setActionInProgress(true);
      await updateCompetitionComment(id, editText);
      toast({
        title: 'Success',
        description: 'Comment updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      selectedCompetitionId && fetchComments(selectedCompetitionId);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong while updating comments.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setActionInProgress(false); // Reset action in progress state
    }
  };

  const onDeleteClick = async (id: any) => {
    try {
      setActionInProgress(true);
      await deleteCompetitionComment(id); // Await the deleteComment function
      selectedCompetitionId && fetchComments(selectedCompetitionId);
      toast({
        title: 'Success',
        description: 'Comment deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: unknown) {
      console.error('Error caught in catch block:', error);
      setLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong while deleting comment',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setActionInProgress(false); // Reset action in progress state
    }
  };

  const addCompetitionComment = async () => {
    try {
      const data = {
        competition: selectedCompetitionId,
        comment: value,
      };
      setActionInProgress(true);
      await addCompetitionComments(selectedCompetitionId || 0, data);
      toast({
        title: 'Success',
        description: 'Comment added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setValue('');
      selectedCompetitionId && fetchComments(selectedCompetitionId);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while adding comment.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setActionInProgress(false); // Reset action in progress state
    }
  };

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Drawer isOpen={isOpen} placement={'right'} onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent
            width={['95% !important', '95% !important', '"586px" !important']}
            className="leader-board-drawer"
            borderRadius={['44px', '44px', 'unset']}
            right={['unset !important', 'unset !important', '0px !important']}
            padding={['unset !important', 'unset !important', '36px !important;']}
            marginTop={['12px', '12px', 'unset']}
            marginBottom={['12px', '12px', 'unset']}
          >
            <Box
              display={['none', 'none', 'flex']}
              borderRadius="15px"
              background="#2C2C2C"
              width="58px"
              height="52px"
              position="absolute"
              left="-92px"
              onClick={onClose}
              // display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
            >
              <CloseIconGray />
            </Box>

            <DrawerHeader p={'0px'}>
              <Box
                borderRadius=" 24px"
                // border=" 0.5px solid rgba(255, 255, 255, 0.10)"
                background="rgba(255, 255, 255, 0.05)"
                padding={['10px', '10px', '24px 18px']}
                display="flex"
                flexDirection="row"
                justifyContent={['flex-end', 'flex-end', 'center']}
                alignItems="center"
                position="relative"
                margin={['18px', '18px', 'unset']}
              >
                <Image
                  src={Trophy}
                  width={['130px']}
                  height={['130px']}
                  position={['absolute']}
                  left="10px"
                  top="-15px"
                />
                <Box
                  display="flex"
                  justifyContent={['space-between', 'space-between', 'space-between']}
                  alignItems="center"
                  width={['200px', '200px', 'unset']}
                >
                  <Text
                    color="#fff"
                    textAlign="center"
                    fontFamily="Montserrat"
                    fontSize={['16px', '16px', '24px']}
                    fontStyle=" normal"
                    fontWeight="800"
                    lineHeight="normal"
                    // marginRight={['30px', '30px', 'unset']}
                  >
                    Comments
                  </Text>
                  <Box
                    borderRadius="15px"
                    borderBottom="2px solid #00C22B"
                    background=" #3AFF65"
                    width="48px"
                    height="44px"
                    onClick={onClose}
                    display={['flex', 'flex', 'none']}
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              </Box>
            </DrawerHeader>
            <DrawerBody p={0} className="custom-scroll">
              <Box
                margin={['23px 20px 20px 20px', '23px 20px 20px 20px', '23px 0px 0px 0px']}
                // marginTop="23px"
                display="flex"
                flexDirection="column"
                gap="15px"
                minH={allComments && allComments?.length === 0 ? 'unset' : '-webkit-fill-available'}
                height={allComments && allComments?.length === 0 ? '520px' : '-webkit-fill-available'}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="15px"
                  overflow="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '0px', // Hides scrollbar in WebKit browsers (Chrome, Safari)
                      backgroundColor: 'transparent',
                    },
                    '*::-webkit-scrollbar-corner': {
                      background: 'transparent',
                    },
                  }}
                  style={{
                    scrollbarWidth: 'none', // Hides scrollbar in Firefox
                  }}
                >
                  {allComments?.length ? (
                    allComments.map((comment, i) => (
                      <Box display="flex" gap="10px" key={i}>
                        <Box>
                          {comment?.user?.profile_picture ? (
                            <Box height="40px" width="40px">
                              <Image
                                src={comment?.user?.profile_picture}
                                objectFit="cover"
                                borderRadius="50%"
                                height="inherit"
                                width="inherit"
                              />
                            </Box>
                          ) : (
                            <Box height="40px" width="40px">
                              <Image
                                src={ProfilePicture}
                                objectFit="cover"
                                height="inherit"
                                width="inherit"
                                borderRadius="50%"
                              />
                            </Box>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          gap="5px"
                          flexDirection="column"
                          padding="12px"
                          borderRadius="16px"
                          background="rgba(255, 255, 255, 0.09)"
                          width="-webkit-fill-available"
                        >
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box display="flex" gap="10px">
                              <Text
                                color="#FFF"
                                fontFamily="Montserrat"
                                fontSize=" 14px"
                                fontStyle="normal"
                                fontWeight="700"
                                lineHeight="22px"
                              >
                                {comment?.user?.username}
                              </Text>
                              <Box height="14px" display="flex" justifyContent="center" alignItems="center">
                                <Text
                                  color="rgba(255, 255, 255, 0.50)"
                                  fontFamily="Montserrat"
                                  fontSize="14px"
                                  fontStyle="normal"
                                  fontWeight="500"
                                  lineHeight="22px"
                                >
                                  .
                                </Text>
                              </Box>
                              <Text
                                color="rgba(255, 255, 255, 0.50)"
                                fontFamily="Montserrat"
                                fontSize={['10px', '14px']}
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="22px"
                              >
                                <TimeAgo timestamp={comment?.updated_at} />
                              </Text>
                            </Box>

                            {comment?.user?.username === userName && (
                              <Menu placement="bottom-end">
                                {({ isOpen, onClose }) => (
                                  <>
                                    <MenuButton
                                      display="flex"
                                      width=" unset"
                                      backgroundColor=" transparent"
                                      rightIcon={isOpen ? <CommentDropDown /> : <CommentDropDown />}
                                      height=" unset"
                                      justifyContent="flex-end"
                                      as={Button}
                                      _hover={{
                                        color: 'white',
                                        bg: 'transparent',
                                      }}
                                      _active={{
                                        color: 'white',
                                        bg: 'transparent',
                                      }}
                                    ></MenuButton>
                                    <MenuList
                                      padding="8px"
                                      borderRadius="8px"
                                      backgroundColor="#585858"
                                      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)"
                                      borderWidth="none !important"
                                      zIndex={2}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        height="36px"
                                        paddingRight="12px"
                                        paddingLeft="12px"
                                        cursor="pointer"
                                        onClick={() => {
                                          setCommentToDeleteId(null);
                                          handleEditClick(comment);
                                          onClose(); // Close the menu after clicking
                                        }}
                                      >
                                        <Text
                                          color="#FFF"
                                          fontFamily="Montserrat"
                                          fontSize=" 14px"
                                          fontStyle=" normal"
                                          fontWeight=" 500"
                                          lineHeight="22px" /* 157.143% */
                                        >
                                          Edit comment
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        height="36px"
                                        paddingRight="12px"
                                        paddingLeft="12px"
                                        cursor="pointer"
                                        onClick={() => {
                                          removeCommentToEditFromState();
                                          setCommentToDeleteId(comment?.id);
                                          onClose();
                                        }}
                                      >
                                        <Text
                                          color="#FF4D4F"
                                          fontFamily="Montserrat"
                                          fontSize=" 14px"
                                          fontStyle=" normal"
                                          fontWeight=" 500"
                                          lineHeight="22px"
                                        >
                                          Delete comment
                                        </Text>
                                      </Box>
                                    </MenuList>
                                  </>
                                )}
                              </Menu>
                            )}
                          </Box>

                          <Box>
                            {editCommentId === comment.id ? (
                              <Box>
                                <Input
                                  value={editText}
                                  onChange={(e) => setEditText(e.target.value)}
                                  placeholder="Edit your comment"
                                  size="sm"
                                  borderRadius="8px"
                                  // background="rgba(255, 255, 255, 0.09)"
                                  border="none"
                                  height="40px"
                                  padding="8px"
                                  color="white"
                                  focusBorderColor="none"
                                />
                                <Box marginTop="12px" gap="5px" display="flex" justifyContent="flex-end">
                                  <Button
                                    borderRadius="8px"
                                    background="rgba(255, 255, 255, 0.15)"
                                    onClick={() => handleCancelClick()}
                                    color="#FFF"
                                    fontSize="14px"
                                    fontWeight="500"
                                    _hover={{
                                      color: '#fff',
                                      bg: 'rgba(255, 255, 255, 0.12)',
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    borderRadius="8px"
                                    borderTop="1px solid #04D3FF"
                                    background="#118BCF"
                                    onClick={() => {
                                      handleUpdateClick(comment.id);
                                    }}
                                    color="#FFF"
                                    fontSize="14px"
                                    fontWeight="500"
                                    _hover={{
                                      color: '#fff',
                                      bg: '#04D3FF',
                                    }}
                                  >
                                    Save changes
                                  </Button>
                                </Box>
                              </Box>
                            ) : (
                              <Text
                                color="#FFF"
                                fontFamily="Montserrat"
                                fontSize=" 14px"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="22px"
                              >
                                {comment?.comment}
                              </Text>
                            )}
                          </Box>

                          {commentToDeleteId === comment?.id && (
                            <Box marginTop="12px" gap="5px" display="flex" justifyContent="flex-end">
                              <Button
                                borderRadius="8px"
                                background=" rgba(255, 255, 255, 0.15)"
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                onClick={() => {
                                  setCommentToDeleteId(null);
                                }}
                                _hover={{
                                  color: '#fff',
                                  bg: 'rgba(255, 255, 255, 0.12)',
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                borderRadius="8px"
                                onClick={() => {
                                  onDeleteClick(comment?.id);
                                }}
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                background=" #FF4D4F"
                                _hover={{
                                  color: 'white',
                                  bg: '#FF4D4F',
                                }}
                              >
                                Delete comment
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box marginTop="10px">
                      <Text
                        color=" rgba(255, 255, 255, 0.50)"
                        textAlign="center"
                        fontFamily="Montserrat"
                        fontSize="16px"
                        fontStyle=" normal"
                        fontWeight="500"
                      >
                        No comments to display.
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box position="relative" width="100%" marginTop="auto">
                  {!accessToken ? (
                    <Box
                      borderRadius="16px"
                      background="rgba(255, 255, 255, 0.09)"
                      height="64px"
                      paddingLeft="40px"
                      paddingRight="40px"
                      fontStyle=" normal"
                      fontWeight=" 500"
                      lineHeight="22px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={['5px', '5px', '5px', '10px', '10px']}
                    >
                      <Text
                        color=" rgba(255, 255, 255, 0.50)"
                        textAlign="center"
                        fontFamily="Montserrat"
                        fontSize="16px"
                        fontStyle=" normal"
                        fontWeight="500"
                      >
                        Please login to comment
                      </Text>
                      <Button
                        width="113px"
                        borderRadius="15px"
                        backgroundColor="#118BCF"
                        padding="16px 32px"
                        color=" #FFF"
                        fontSize="16px"
                        fontWeight=" 600"
                        h="44px"
                        _hover={{
                          color: 'white',
                          bg: '#43BDD7',
                        }}
                        onClick={() => navigate('/login')}
                      >
                        Login
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      {currentUser?.profile_picture ? (
                        <Image
                          src={currentUser?.profile_picture}
                          alt="Left Image"
                          position="absolute"
                          left="10px"
                          top="50%"
                          transform="translateY(-50%)"
                          boxSize="20px"
                          borderRadius="50%"
                          height="34px"
                          width="34px"
                        />
                      ) : (
                        <Image
                          src={ProfilePicture}
                          alt="Left Image"
                          position="absolute"
                          left="10px"
                          top="50%"
                          transform="translateY(-50%)"
                          boxSize="20px"
                          borderRadius="50%"
                          height="34px"
                          width="34px"
                        />
                      )}
                      <Input
                        value={value}
                        onChange={handleInputChange}
                        placeholder="Add a comment"
                        size="sm"
                        borderRadius="16px"
                        background="rgba(255, 255, 255, 0.09)"
                        border="none"
                        height="54px"
                        paddingLeft="60px"
                        paddingRight="40px"
                        focusBorderColor="rgba(255, 255, 255, 0.10)"
                        _placeholder={{
                          color: 'rgba(255, 255, 255, 0.50)',
                          fontFamily: 'Montserrat',
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '22px' /* 146.667% */,
                        }}
                        color="#FFF"
                        fontSize=" 15px"
                        fontStyle=" normal"
                        fontWeight=" 500"
                        lineHeight="22px" /* 146.667% */
                        marginRight="10px"
                      />
                      <Box
                        position="absolute"
                        right="10px"
                        top="50%"
                        transform="translateY(-50%)"
                        cursor="pointer"
                        onClick={addCompetitionComment}
                        zIndex="1"
                      >
                        <Image src={value ? SendMessageActive : SendMessageImage} alt="Right Image" boxSize="20px" />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
