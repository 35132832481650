import { Box, Spinner, Image, Text, Button } from '@chakra-ui/react';
import Logo from '../../assets/logo.png';
import React, { useEffect } from 'react';
import { AppStoreState, useAppStore } from '../../store';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import LinkWalletIcon from '../../Icons/LinkWallet';
import { useAccount } from 'wagmi';
import useUser from '../../hooks/useUser';

export default function LinkWallet() {
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const { address } = useAccount();
  const { isLoading, user } = useUser();
  // const navigate = useNavigate();

  const { open } = useWeb3Modal();

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  useEffect(() => {
    if (!isLoading && address) {
      // navigate('/');
    }
  }, [isLoading, address]);

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Box
            padding="1px"
            borderRadius="24px"
            backgroundColor="#1F1F1F"
            display="flex"
            zIndex={2}
            style={{
              borderRadius: '24px',
              backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
              backgroundOrigin: 'border-box',
              backgroundClip: ' content-box, border-box',
            }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={['20px 20px', '36px', '36px']}
              display="flex"
            >
              <Image src={Logo} w="176px" height="42px" />
              <Text fontWeight={500} fontSize="18px" color="white" marginTop="24px">
                Welcome <span style={{ color: '#04D3FF' }}>{user?.username}!</span>
              </Text>
              <Text fontWeight={500} fontSize="18px" color="rgba(255, 255, 255, 0.50)" marginTop="24px">
                Now let’s link your wallet
              </Text>
              <Button
                marginTop="24px"
                leftIcon={<LinkWalletIcon />}
                width={['100%', '100%', '344px', '344px']}
                borderRadius=" 15px"
                borderTop=" 1px solid #43BDD7"
                backgroundColor="#118BCF"
                padding="16px 32px"
                color=" #FFF"
                fontSize=" 20px"
                fontWeight=" 600"
                h="48px"
                _hover={{
                  color: 'white',
                  bg: '#43BDD7',
                }}
                onClick={() => handleLogin()}
                disabled={isLoading}
              >
                Link Wallet
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
