import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Image,
  Divider,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Trophy from '../../assets/trophy.png';
import ProfileIconGrey from '../../Icons/ProfileGrey';
import CloseIcon from '../../Icons/Close';
import { AppStoreState, useAppStore } from '../../store';
import { getLeaderBoardData } from '../../api/competitions/apis';
import { LeaderBoardApiResponse } from '../../interfaces/components';

interface LeaderBoardDrawerProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const LeaderBoardDrawer = (props: LeaderBoardDrawerProps) => {
  const { isOpen, onClose } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardApiResponse[]>();
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const toast = useToast();

  const fetchLeaderBoardRanking = async (competitionId: number) => {
    try {
      setIsDataLoading(true);
      const res = await getLeaderBoardData(competitionId);
      setLeaderBoardData(res?.data);
      setIsDataLoading(false);
    } catch (error: any) {
      setIsDataLoading(false);
      toast({
        title: 'Something went wrong while fetching LeaderBoard Ranking.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      selectedCompetitionId && fetchLeaderBoardRanking(selectedCompetitionId);
    }
  }, [isOpen]);

  return (
    <>
      {isDataLoading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center"></Box>
      ) : (
        <Drawer isOpen={isOpen} placement={'right'} onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent
            width={['95% !important', '95% !important', '"586px" !important']}
            className="leader-board-drawer"
            borderRadius={['44px', '44px', 'unset']}
            right={['unset !important', 'unset !important', '0px !important']}
            padding={['unset !important', 'unset !important', '36px !important;']}
            marginTop={['12px', '12px', 'unset']}
            marginBottom={['12px', '12px', 'unset']}
          >
            <Box
              display={['none', 'none', 'flex']}
              borderRadius="15px"
              borderBottom=" 2px solid #00C22B"
              background=" #3AFF65"
              width="58px"
              height="52px"
              position="absolute"
              left="-92px"
              onClick={onClose}
              // display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
            >
              <CloseIcon />
            </Box>

            <DrawerHeader p={'0px'}>
              <Box
                borderRadius=" 24px"
                // border=" 0.5px solid rgba(255, 255, 255, 0.10)"
                background="rgba(255, 255, 255, 0.05)"
                padding={['10px', '10px', '24px 18px']}
                display="flex"
                flexDirection="row"
                justifyContent={['flex-end', 'flex-end', 'center']}
                alignItems="center"
                position="relative"
                margin={['18px', '18px', 'unset']}
              >
                <Image
                  src={Trophy}
                  width={['130px']}
                  height={['130px']}
                  position={['absolute']}
                  left="10px"
                  top="-15px"
                />
                <Box
                  display="flex"
                  justifyContent={['space-between', 'space-between', 'space-between']}
                  alignItems="center"
                  width={['200px', '200px', 'unset']}
                >
                  <Text
                    color="#fff"
                    textAlign="center"
                    fontFamily="Montserrat"
                    fontSize={['16px', '16px', '24px']}
                    fontStyle=" normal"
                    fontWeight="800"
                    lineHeight="normal"
                    // marginRight={['30px', '30px', 'unset']}
                  >
                    LEADERBOARD
                  </Text>
                  <Box
                    borderRadius="15px"
                    borderBottom="2px solid #00C22B"
                    background=" #3AFF65"
                    width="48px"
                    height="44px"
                    onClick={onClose}
                    display={['flex', 'flex', 'none']}
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              </Box>
              <Box
                marginTop="14px"
                borderRadius={['unset', 'unset', '100px']}
                background="rgba(255, 255, 255, 0.05)"
                padding="9px 18px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontStyle=" normal"
                  fontWeight="600"
                  lineHeight="normal"
                  color=" #959595"
                >
                  #Rank
                </Text>
                <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" orientation="vertical" height="20px" />
                <Text
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontStyle=" normal"
                  fontWeight="600"
                  lineHeight="normal"
                  color="#959595"
                >
                  Votes
                </Text>
              </Box>
            </DrawerHeader>
            <DrawerBody p={0} className="custom-scroll">
              {leaderBoardData &&
                leaderBoardData.map((data, i) => (
                  <>
                    <Box key={i} padding="20px" display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap="18px">
                        <Box display="flex" justifyContent="flex-start" alignItems="center" width="30px">
                          <Text
                            color={i === 0 ? '#FFBC00' : i === 1 ? '#B8B8B8' : i === 2 ? '#FF993A' : '#FFF'}
                            textAlign="center"
                            fontFamily="Montserrat"
                            fontSize="18px"
                            fontStyle="normal"
                            fontWeight="800"
                            lineHeight="normal"
                          >
                            {i + 1}
                          </Text>
                        </Box>
                        <Box display="flex" flexDirection="row" gap="10px" justifyContent="center" alignItems="center">
                          {data?.profile_picture ? (
                            <Box height="34px" width="34px">
                              <Image
                                src={data?.profile_picture}
                                objectFit="cover"
                                borderRadius="50%"
                                height="inherit"
                                width="inherit"
                              />
                            </Box>
                          ) : (
                            <ProfileIconGrey />
                          )}

                          <Text
                            color="#fff"
                            textAlign="center"
                            fontFamily="Montserrat"
                            fontSize="15px"
                            fontStyle=" normal"
                            fontWeight="600"
                            lineHeight="normal"
                          >
                            {data?.username}
                          </Text>
                        </Box>
                      </Box>
                      <Box>
                        <Text
                          color="#3AFF65"
                          textAlign="center"
                          fontFamily="Montserrat"
                          fontSize="16px"
                          fontStyle=" normal"
                          fontWeight="600"
                          lineHeight="normal"
                        >
                          {data?.vote_count}
                        </Text>
                      </Box>
                    </Box>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" width="99%" />
                  </>
                ))}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
