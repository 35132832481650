import { Box, Text } from '@chakra-ui/react';
import ArrowRightIcon from '../../Icons/ArrowRight';
import ArrowLeftIcon from '../../Icons/ArrowLeft';
import React from 'react';

interface PaginationProps {
  handlePreviousPage: () => void;
  totalPages: number;
  currentPage: number;
  onPaginationitemClick: (index: number) => void;
  handleNextPage: () => void;
}

export default function Pagination(props: PaginationProps) {
  const { handlePreviousPage, totalPages, currentPage, onPaginationitemClick, handleNextPage } = props;
  return (
    <>
      {totalPages && (
        <Box display="flex" alignItems="center" justifyContent={['center', 'center', 'flex-end']} marginTop="56px">
          <Box display="flex" alignItems="center" gap="8px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={handlePreviousPage}
            >
              <ArrowLeftIcon />
            </Box>
            {Array(Number(totalPages))
              .fill(0)
              .map((e, i) => (
                <Box
                  key={e}
                  height="40px"
                  width="40px"
                  display="flex"
                  justifyContent="center"
                  borderRadius="50%"
                  alignItems="center"
                  backgroundColor={i + 1 === currentPage ? '#118BCF' : 'unset'}
                  cursor="pointer"
                  onClick={() => onPaginationitemClick(i + 1)}
                >
                  <Text
                    color={i + 1 === currentPage ? '#FFF' : '#7C7C82'}
                    textAlign="center"
                    fontFamily="Inter"
                    fontSize=" 16px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="20px"
                  >
                    {i + 1}
                  </Text>
                </Box>
              ))}
            <Box display="flex" justifyContent="center" alignItems="center" cursor="pointer" onClick={handleNextPage}>
              <ArrowRightIcon />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
