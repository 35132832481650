import { Box, useDisclosure, Image } from '@chakra-ui/react';
import greenBlueBgGradient from '../../assets/greenBlueBgGradient.png';
import React, { useEffect, useState } from 'react';
import { NavbarOption } from '../../interfaces/components';
import { useLocation } from 'react-router-dom';
import Header from '../header/Header';
import { navbarOptions } from '../../content';
import Footer from '../home/Footer';
import Drawer from './Drawer';

export interface OverlayProps {
  children: React.ReactNode;
}

function Overlay({ children }: OverlayProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [activeView, setActiveView] = useState('payouts');
  const [showHeader, setShowHeader] = useState(false);

  function containsAny(targetString: string, arrayOfStrings: string[]) {
    return arrayOfStrings.some((subString) => targetString.includes(subString));
  }

  const blackListedRoutes = [
    'link-wallet',
    // 'my-profile',
    'mint-nft',
    'minted-nft',
    'nft-overview',
  ];

  useEffect(() => {
    const activeSidebarOption = navbarOptions.find(
      (navbarOption: NavbarOption) => navbarOption.href === location.pathname,
    );

    if (containsAny(location.pathname, blackListedRoutes)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
  }, [location.pathname]);

  return (
    <Box w="100%" minH="100vh" display="flex" flexDirection="column" overflow="hidden" backgroundColor="#0C0C0C">
      <Box
        position="absolute"
        w="100%"
        top={['-50px', '-50px', '-50px', '-450px']}
        zIndex={0}
        pointerEvents="none"
        display={showHeader ? 'unset' : 'none'}
      >
        <Image src={greenBlueBgGradient} w="100%" h="auto" objectFit="contain" />
      </Box>

      <Box
        position="absolute"
        w="100%"
        height="100vh"
        zIndex={0}
        pointerEvents="none"
        display={showHeader ? 'none' : 'unset'}
      >
        <Image src={greenBlueBgGradient} w="100%" h="100vh" objectFit="contain" />
      </Box>

      <Box position="relative" zIndex={2} w="100%">
        <Header activeView={activeView} navbarOptions={navbarOptions} openDrawer={onOpen} />
        <Box display={['block', 'block', 'none', 'none', 'none']}>
          <Drawer isOpen={isOpen} onClose={onClose} />
        </Box>
      </Box>

      <Box w="100%" flex="1" position="relative" zIndex={1} display="flex" justifyContent="center" alignItems="center">
        <Box
          position="relative"
          zIndex={2}
          maxWidth={['unset', 'unset', '1400px']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0px 30px', '0px 30px']}
          width="100%"
        >
          {children}
        </Box>

        <Box
          position="absolute"
          w="100%"
          bottom="-300px"
          zIndex={0}
          pointerEvents="none"
          display={showHeader ? 'unset' : 'none'}
        >
          <Image src={greenBlueBgGradient} w="100%" h="auto" objectFit="contain" transform="rotate(180deg)" />
        </Box>
      </Box>
      {location?.pathname === '/resources' ||
      location?.pathname === '/privacyPolicy' ||
      location?.pathname === '/termsAndCondition' ||
      location?.pathname === '/mint-nft' ||
      location?.pathname === '/all-set-screen' ||
      location?.pathname === '/minted-nft' ||
      location?.pathname === '/auth-callback/' ||
      location?.pathname === '/link-wallet' ? (
        <Box zIndex={1} maxWidth="1482px" width={'95%'} margin="0 auto" padding="0px 0px 16px 0px">
          <Footer />
        </Box>
      ) : (
        <Box w="100%" flex="1" position="relative" zIndex={1} display="flex" justifyContent="center">
          <Box
            position="relative"
            zIndex={2}
            maxWidth={['unset', 'unset', '1400px']}
            padding={['0px 20px', '0px 20px', '0px 20px', '0px 30px', '0px 30px']}
            pb={['20px', '20px', '20px', '30px', '30px']}
            width="100%"
          >
            <Footer />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Overlay;
