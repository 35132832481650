import { Box, Text, Image, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppStoreState, useAppStore } from '../../store';
// import TweetImage from '../../assets/tweetImage.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function MintedNft() {
  const navigate = useNavigate();
  const query = useQuery();
  const url = query.get('url');
  const mintedImage = useAppStore((state: AppStoreState) => state.mintedImage);

  useEffect(() => {
    if (!url) {
      // navigate('/');
    }
  }, [url, navigate]);

  return (
    <Box display="flex" justifyContent="center" height="100%" width="100%" overflow="auto">
      <Box
        padding="70px 0px"
        width="500px"
        height="fit-content"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text color="#FFF" fontSize="28px" fontWeight="700" textAlign="center">
          You just minted a new NFT
        </Text>
        {mintedImage && (
          <Image
            width="100%"
            // src={TweetImage}
            src={mintedImage}
            marginTop="36px"
            zIndex={4}
          />
        )}

        <Box
          padding="1px"
          backgroundColor="#1F1F1F"
          marginTop="-30px"
          zIndex={3}
          style={{
            borderRadius: '24px',
            backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
            backgroundOrigin: 'border-box',
            backgroundClip: ' content-box, border-box',
          }}
        >
          <Box
            display="flex"
            padding={['20px 20px', '36px', '56px 36px 36px 36px']}
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            gap="20px"
          >
            <Button
              width="232px"
              borderRadius="15px"
              padding="16px 32px"
              color="#FFF"
              fontSize="20px"
              fontWeight="600"
              height="56px"
              _hover={{
                color: 'white',
                bg: '#7B7B7B',
              }}
              borderTop=" 1px solid #7B7B7B"
              backgroundColor="#535353"
              onClick={() => navigate('/')}
            >
              Cancel
            </Button>
            <Button
              width="232px"
              borderRadius="15px"
              borderTop="1px solid #43BDD7"
              backgroundColor="#118BCF"
              padding="16px 32px"
              color="#FFF"
              fontSize="20px"
              fontWeight="600"
              height="56px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => navigate('/nfts')}
            >
              View Collection
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
