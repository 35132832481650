import { Box, Text, Image } from '@chakra-ui/react';
import localPolice from '../../assets/localPolice.svg';
import React from 'react';

export default function ProgressHero() {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection={['column']}
        marginBottom={['unset', 'unset', '56px']}
        borderRadius={['36px']}
        gap={['24px', '24px', 'unset']}
        padding="36px"
        background="radial-gradient(1260.58% 227.12% at 106.21% 87.11%, rgba(4, 211, 255, 0.30) 0%, rgba(58, 255, 101, 0.30) 100%)"
      >
        <Box
          borderRadius="25px"
          border=" 0.5px solid rgba(255, 255, 255, 0.20)"
          background="#222"
          display="flex"
          alignItems="center"
          padding="12px 24px"
          width="fit-content"
          gap="6px"
        >
          <Image
            src={localPolice}
            //   width={['-webkit-fill-available;', '-webkit-fill-available;', 'unset', '533px']}
          />
          <Text
            color=" #FFF"
            fontFamily=" Montserrat"
            fontSize="21px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="normal"
          >
            User Levels
          </Text>
        </Box>
        <Text
          color="#FFF"
          fontFamily="Montserrat"
          fontSize={['46px', '46px', '64px']}
          fontStyle="normal"
          fontWeight="700"
          lineHeight="normal"
          marginTop="36px"
        >
          Make strides with your unique NFTs
        </Text>
        <Text
          color="rgba(255, 255, 255, 0.50)"
          fontFamily="Montserrat"
          fontSize={['16px', '16px', '20px']}
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
          marginTop="16px"
        >
          Gain XP by minting NFTs and rise up the Ranks!
        </Text>
      </Box>
    </Box>
  );
}
