import { Box, Text, Image, useToast, Divider, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import EthereumLogo from '../../Icons/Ethereum';
import HeartIcon from '../../Icons/HeartcIcon';
import { useLocation } from 'react-router-dom';
import { addNftToFavorite, getNftDetails } from '../../api/nfts/apis';
import { useAppStore, AppStoreState } from '../../store';
import VerifiedLogo from '../../Icons/Verified';
import { formatAddress } from '../../utils/commonFunctions';
import { NftType } from '../../interfaces/components';
import CommentSection from './CommentSection';

export default function NftDetails() {
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const location = useLocation();
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const [nftDetail, setNftDetail] = useState<NftType>();
  const [nftLiked, setNftLiked] = useState(false);

  const fetchData = async (tokenId: string) => {
    try {
      setLoading(true);
      const res = await getNftDetails(tokenId);
      res && setNftDetail(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFT Details.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const tokenId = location && location?.pathname?.split('/').pop();
    if (tokenId) {
      fetchData(tokenId);
    }
  }, [reRender, location?.pathname]);

  const onAddToFavClick = async () => {
    try {
      const res = await addNftToFavorite(nftDetail?.identifier || '');
      console.log('fav res', res);
      if (res?.message === 'NFT liked') {
        setNftLiked(true);
        toast({
          title: 'Nft added to favorite successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while adding nft to favorite.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removeFromFavorites = async () => {
    try {
      const res = await addNftToFavorite(nftDetail?.identifier || '');
      console.log('fav res', res);
      if (res?.message === 'NFT unliked') {
        setNftLiked(false);
        toast({
          title: 'Nft removed from favorites successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while removing nft from favorite.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          {/* <Spinner /> */}
        </Box>
      ) : (
        <Box
          // margin="0px 16px"
          padding="12px 0px 64px 0px"
          display={['flex', 'flex', 'block']}
          flexDirection={['column', 'column', 'unset']}
        >
          <Box>
            <Text color="rgba(255, 255, 255, 0.50)" fontSize="14px" fontWeight="600">
              NFTs Overview/{' '}
              <span
                style={{
                  color: '#FFF',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                NFT Preview
              </span>
            </Text>
          </Box>

          <Box marginTop="24px" display="flex" flexDirection={['column', 'column', 'row', 'row']} gap="24px">
            <Box
              borderRadius="26px"
              objectFit="cover"
              // maxHeight="334px"
              overflow="auto"
              display={['block', 'block', 'none', 'none', 'none']}
              width="-webkit-fill-available"
            >
              <Image src={nftDetail?.display_image_url} objectFit="cover" width="inherit" borderRadius="26px" />
            </Box>
            <Box width={['100%', '100%', '50%']} display="flex" flexDirection="column" gap="24px">
              <Box
                borderRadius="26px"
                objectFit="cover"
                maxHeight="334px"
                overflow="auto"
                display={['none', 'none', 'block', 'block', 'block']}
                width="-webkit-fill-available"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '8px !important',
                    backgroundColor: 'transparent !important',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(255,255,255,0.2) !important',
                    borderRadius: '16px !important',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#b7b7b7 !important',
                    borderRadius: '16px !important',
                    border: '0px solid transparent !important',
                    backgroundClip: 'content-box !important',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#8c8c8c !important',
                  },
                  '*::-webkit-scrollbar-corner': {
                    background: 'transparent !important',
                  },
                }}
                style={{
                  scrollbarWidth: 'auto',
                }}
              >
                <Image
                  // src={TweetImage}
                  src={nftDetail?.display_image_url}
                  objectFit="cover"
                  width="inherit"
                  borderRadius="26px"
                  marginRight="20px"
                />
              </Box>
              <Box
                borderRadius="26px"
                background="rgba(255, 255, 255, 0.10)"
                padding="16px"
                width="-webkit-fill-available"
                height="fit-content"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Text color=" #FFF" fontSize="24px" fontWeight="700">
                    {nftDetail?.name}
                  </Text>
                  <Text color="rgba(255, 255, 255, 0.50)" fontSize="14px" fontWeight="600" textDecoration="underline">
                    Share
                  </Text>
                </Box>
                <Box marginTop="24px" marginBottom="10px">
                  <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                </Box>
                <Box display="flex" flexDirection="column" gap="5px">
                  <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                    <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                      Network
                    </Text>
                    <Box
                      borderRadius="19px"
                      background="rgba(255, 255, 255, 0.10)"
                      padding="4px 4px 4px 10px"
                      display="flex"
                      gap="6px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                        Ethereum
                      </Text>
                      <EthereumLogo boxSize={6} />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                    <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                      Creator
                    </Text>
                    {nftDetail?.owner ? (
                      <Box display="flex" justifyContent="center" gap="6px">
                        <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                          {formatAddress(nftDetail?.owner, 5, 5)}
                        </Text>
                        <VerifiedLogo />
                      </Box>
                    ) : (
                      <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                        -
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box marginTop="24px" marginBottom="10px">
                  <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="18px" />
                </Box>
                <Box display="flex" gap="12px">
                  <Button
                    width="100%"
                    borderRadius="15px"
                    backgroundColor="#222"
                    padding="16px 32px"
                    color=" #FFF"
                    fontSize="16px"
                    fontWeight=" 600"
                    border=" 0.5px solid rgba(255, 255, 255, 0.20)"
                    h="44px"
                    _hover={{
                      color: 'white',
                      bg: 'rgba(255, 255, 255, 0.20)',
                    }}
                    leftIcon={<HeartIcon />}
                    onClick={nftLiked ? removeFromFavorites : onAddToFavClick}
                  >
                    {nftLiked ? 'Remove From Favorites' : 'Add to Favorites'}
                  </Button>
                </Box>
              </Box>
            </Box>
            <CommentSection nftId={parseInt(nftDetail?.identifier || '')} />
          </Box>
        </Box>
      )}
    </>
  );
}
