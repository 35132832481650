import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  Image,
  Divider,
  Button,
  DrawerFooter,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AppMenuItem, DrawerProps } from '../../interfaces/components';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Hamburger from '../../Icons/Hamburger';
import { menuItems } from '../header/Header';
import { useAppStore, AppStoreState } from '../../store';
import storage from '../../utils/storage';
import ProfileIcon from '../../Icons/profile';
import LogoutLogo from '../../Icons/LogoutLogo';
import { useUser } from '../../hooks';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { formatAddress } from '../../utils/commonFunctions';
import PowerSettingIcon from '../../Icons/PoweSettingIcon';

function Drawer({ isOpen, onClose }: DrawerProps) {
  const walletAddress = storage.get('walletAddress');
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const userName = storage.get('userName');
  const navigate = useNavigate();
  const [activeView, setActiveView] = useState('/');
  const location = useLocation();
  const { logout } = useUser();
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  useEffect(() => {
    const activeSidebarOption = menuItems.find((navbarOption: AppMenuItem) => navbarOption.path === location.pathname);

    activeSidebarOption && setActiveView(activeSidebarOption.path);
  }, [location.pathname]);

  const onNavbarItemClick = (option: AppMenuItem) => {
    if (option?.path === '/my-profile') {
      navigate('/my-profile');
    } else {
      navigate(option?.path);
    }
    onClose();
  };

  const onLogoutClick = async () => {
    await logout();
  };

  const handleLogin = async () => {
    open({ view: 'Networks' }).then((res) => {
      console.log('handle login res network', res);
    });
  };

  const onConnectButtonClick = async () => {
    if (!address) {
      handleLogin();
    } else {
      console.log('from else');
    }
  };

  return (
    <ChakraDrawer isOpen={isOpen} placement="right" onClose={onClose} autoFocus={false} size={'lg'}>
      <DrawerOverlay />
      <DrawerContent
        width={['80% !important', '95% !important', '100% !important']}
        borderRadius="26px"
        background=" #242424"
        padding="16px"
        height="fit-content"
        right="unset !important"
        marginTop="10px"
        display={['unset', 'unset', 'none', 'none', 'none']}
      >
        <DrawerHeader display="flex" justifyContent="space-between" alignItems="center" padding="initial">
          <Image src={Logo} height={'24px'} />
          <Hamburger cursor="pointer" onClick={onClose} />
        </DrawerHeader>
        <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginTop="18px" marginBottom="8px" />
        <DrawerBody padding="0px" overflow="unset !important">
          <Box
            paddingTop="12px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            {menuItems.map((navbarOption: AppMenuItem) => (
              <Box
                key={navbarOption.id}
                width="100%"
                fontFamily="Montserrat"
                fontWeight="600"
                fontSize="14px"
                color={activeView === navbarOption.path ? '#FFFFFF' : 'rgba(255, 255, 255, 0.50)'}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                transition="0.2s all"
                cursor="pointer"
                onClick={() => onNavbarItemClick(navbarOption)}
                marginBottom="24px"
              >
                {navbarOption.text}
              </Box>
            ))}
            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginTop="18px" marginBottom="18px" />

            {accessToken && (
              <Box width="-webkit-fill-available">
                <Box display={'flex'} gap="10px">
                  <ProfileIcon />
                  <Text color="rgba(255, 255, 255, 0.50)" fontSize=" 15px" fontWeight="500" textTransform="capitalize">
                    {userName}
                  </Text>
                </Box>
                {address ? (
                  <Box
                    marginTop="18px"
                    borderRadius="14px"
                    background="#000"
                    padding="9px"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text color="rgba(255, 255, 255, 0.50)" fontWeight="500" fontSize="16px">
                      {address && formatAddress(address, 5, 4)}
                    </Text>
                    <PowerSettingIcon />
                  </Box>
                ) : (
                  <Box marginTop="18px" borderRadius="24px" background="#000" padding="16px">
                    <Text color="rgba(255, 255, 255, 0.50)" fontSize="12px" fontWeight="500">
                      Link wallet
                    </Text>
                    <Text color="#fff" fontSize="16px" fontWeight="600">
                      {walletAddress && formatAddress(walletAddress, 5, 5)}
                    </Text>
                    <Button
                      width="100%"
                      borderRadius="13px"
                      backgroundColor="#118BCF"
                      padding="12px 24px"
                      color=" #FFF"
                      fontSize="16px"
                      fontWeight=" 600"
                      h="44px"
                      _hover={{
                        color: 'white',
                        bg: '#43BDD7',
                      }}
                      onClick={() => {
                        // navigate('/login');
                        onConnectButtonClick();
                        onClose();
                      }}
                      marginTop="8px"
                    >
                      Connect
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DrawerBody>

        <DrawerFooter padding="0px !important">
          {accessToken ? (
            <Box display="flex" flexDirection="column" width="-webkit-fill-available">
              <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginTop="18px" marginBottom="18px" />
              <Button
                padding="16px 32px"
                color="rgba(255, 255, 255, 0.50)"
                fontSize="15px"
                fontWeight="500"
                h="44px"
                zIndex={99}
                _hover={{
                  color: 'white',
                  bg: 'rgba(255, 255, 255, 0.50)',
                }}
                onClick={onLogoutClick}
                width="100%"
                leftIcon={<LogoutLogo />}
                borderRadius=" 15px"
                border=" 0.5px solid rgba(255, 255, 255, 0.10)"
                backgroundColor="rgba(255, 255, 255, 0.05)"
                cursor="pointer"
              >
                Logout
              </Button>
            </Box>
          ) : (
            <>
              <Button
                width="100%"
                borderRadius="15px"
                backgroundColor="#118BCF"
                padding="16px 32px"
                color=" #FFF"
                fontSize="16px"
                fontWeight=" 600"
                h="44px"
                _hover={{
                  color: 'white',
                  bg: '#43BDD7',
                }}
                onClick={() => {
                  navigate('/login');
                  onClose();
                }}
              >
                Sign up
              </Button>
            </>
          )}
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
}

export default Drawer;
