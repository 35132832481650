import { Icon } from '@chakra-ui/icons';
import React from 'react';

interface TrophyIconProps {
  isMobile?: boolean;
}

export default function TrophyIcon(props: TrophyIconProps) {
  const { isMobile } = props;
  return (
    <Icon
      width={isMobile ? '20px' : '24px'}
      height={isMobile ? '20px' : '24px'}
      viewBox={isMobile ? '0 0 20 20' : '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="mask0_808_5447" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_808_5447)">
        <path
          d="M7 10.8V7H5V8C5 8.63333 5.18333 9.20417 5.55 9.7125C5.91667 10.2208 6.4 10.5833 7 10.8ZM17 10.8C17.6 10.5833 18.0833 10.2208 18.45 9.7125C18.8167 9.20417 19 8.63333 19 8V7H17V10.8ZM11 19V15.9C10.1833 15.7167 9.45417 15.3708 8.8125 14.8625C8.17083 14.3542 7.7 13.7167 7.4 12.95C6.15 12.8 5.10417 12.2542 4.2625 11.3125C3.42083 10.3708 3 9.26667 3 8V7C3 6.45 3.19583 5.97917 3.5875 5.5875C3.97917 5.19583 4.45 5 5 5H7C7 4.45 7.19583 3.97917 7.5875 3.5875C7.97917 3.19583 8.45 3 9 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5H19C19.55 5 20.0208 5.19583 20.4125 5.5875C20.8042 5.97917 21 6.45 21 7V8C21 9.26667 20.5792 10.3708 19.7375 11.3125C18.8958 12.2542 17.85 12.8 16.6 12.95C16.3 13.7167 15.8292 14.3542 15.1875 14.8625C14.5458 15.3708 13.8167 15.7167 13 15.9V19H16C16.2833 19 16.5208 19.0958 16.7125 19.2875C16.9042 19.4792 17 19.7167 17 20C17 20.2833 16.9042 20.5208 16.7125 20.7125C16.5208 20.9042 16.2833 21 16 21H8C7.71667 21 7.47917 20.9042 7.2875 20.7125C7.09583 20.5208 7 20.2833 7 20C7 19.7167 7.09583 19.4792 7.2875 19.2875C7.47917 19.0958 7.71667 19 8 19H11Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
