import { Text, Modal, ModalBody, ModalContent, ModalOverlay, Box, useToast, Spinner, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import NftListView from '../allNfts/NftListView';
import { SubmitConfirmationModal } from './SubmitConfirmation';
import { AllNftsResponse } from '../../interfaces/components';
import { getMyNfts } from '../../api/nfts/apis';
import { useAppStore, AppStoreState } from '../../store';
import storage from '../../utils/storage';
import MintNft from '../Mint/MintNft';
import { useNavigate } from 'react-router-dom';

interface BrowseNftsModalProps {
  isBrowseModalOpen: boolean;
  onBrowseModalOpen: () => void;
  onBrowseModalClose: () => void;
  submitClicked?: boolean;
  setSubmitClicked?: (submitted: boolean) => void;
}

export const BrowseNftsModal = (props: BrowseNftsModalProps) => {
  const walletAddress = storage.get('walletAddress');
  const { isBrowseModalOpen, onBrowseModalClose, submitClicked, setSubmitClicked } = props;
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const mintNowView = useAppStore((state: AppStoreState) => state.mintNowView);
  const [myNfts, setMyNfts] = useState<AllNftsResponse>();
  const selectedNft = useAppStore((state: AppStoreState) => state.selectedNft);
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const navigate = useNavigate();

  const fetchData = async (page: number, recordsPerPage: number, walletAddress: string) => {
    try {
      setLoading(true);
      const res = walletAddress && (await getMyNfts(page, recordsPerPage, walletAddress));
      res && setMyNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    walletAddress && fetchData(1, 10, walletAddress);
  }, [walletAddress]);

  const onMintNowClick = () => {
    if (accessToken) {
      navigate('/mint-nft');
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      {false ? (
        // !walletAddress
        <p>Please connect your wallet first</p>
      ) : (
        <Modal
          isCentered={mintNowView && !selectedNft ? true : myNfts?.data?.length === 0 ? true : false}
          size={[
            mintNowView && !selectedNft ? 'unset' : 'full',
            mintNowView && !selectedNft ? 'unset' : 'full',
            'unset',
            'unset',
          ]}
          isOpen={isBrowseModalOpen}
          onClose={onBrowseModalClose}
        >
          <ModalOverlay />
          {selectedNft ? (
            <SubmitConfirmationModal
              submitClicked={submitClicked}
              setSubmitClicked={setSubmitClicked}
              onBrowseModalClose={onBrowseModalClose}
              heading={mintNowView ? 'You just minted a new NFT' : 'Submit this NFT?'}
            />
          ) : (
            <>
              {loading ? (
                <>
                  <ModalContent className="loading-modal">
                    <ModalBody>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap="8px"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          color="rgba(255, 255, 255, 0.50)"
                          fontFamily="Montserrat"
                          fontSize="20px"
                          fontWeight="600"
                          lineHeight=" normal"
                          marginBottom="20px"
                          marginTop="20px"
                        >
                          Minting your NFT
                        </Text>
                        <Spinner />
                      </Box>
                    </ModalBody>
                  </ModalContent>
                </>
              ) : (
                <>
                  {mintNowView ? (
                    <>
                      <ModalContent className="mint-now-modal">
                        <ModalBody>
                          <MintNft
                            heading="Mint a new NFT"
                            subHeading="Enter the link of the Tweet you’d like to Mint"
                            btnText="Mint"
                          />
                        </ModalBody>
                      </ModalContent>
                    </>
                  ) : myNfts?.data && myNfts?.data.length > 0 ? (
                    <ModalContent className="browse-nft-modal">
                      <ModalBody
                        pb={6}
                        className="modal-body"
                        maxH={['100vh', '100vh', '85vh']}
                        overflowY="auto"
                        sx={{
                          '::-webkit-scrollbar': { display: 'none' },
                          '-ms-overflow-style': 'none', // IE and Edge
                          'scrollbar-width': 'none', // Firefox
                        }}
                      >
                        <Box>
                          <Text
                            color="#FFF"
                            fontFamily="Montserrat"
                            fontSize="36px"
                            fontStyle="normal"
                            fontWeight="800"
                            lineHeight="normal"
                          >
                            Select NFT
                          </Text>
                          <Box marginTop="24px">
                            {myNfts?.data && (
                              <NftListView
                                allNfts={myNfts?.data}
                                showButton={false}
                                onBrowseModalClose={onBrowseModalClose}
                              />
                            )}
                          </Box>
                        </Box>
                      </ModalBody>
                    </ModalContent>
                  ) : (
                    <ModalContent className="empty-browse-modal">
                      <ModalBody pb={0}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="8px"
                          alignItems="center"
                        >
                          <Text
                            color="#fff"
                            fontFamily="Montserrat"
                            fontSize="36px"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="normal"
                          >
                            Select Nfts
                          </Text>
                          <Text
                            color=" rgba(255, 255, 255, 0.10)"
                            fontFamily="Montserrat"
                            fontSize="18px"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="normal"
                          >
                            No nfts to select,please mint nfts first.
                          </Text>
                          <Button
                            borderRadius="15px"
                            borderTop="1px solid #04D3FF"
                            padding="16px 32px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            fontSize={['16px', '16px', '20px']}
                            fontWeight="600"
                            h={['52px', '52px', '56px']}
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            marginTop="15px"
                            onClick={onMintNowClick}
                          >
                            Mint now
                          </Button>
                        </Box>
                      </ModalBody>
                    </ModalContent>
                  )}
                </>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  );
};
