import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export default function ArrowLeftIcon(props: IconProps) {
  return (
    <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_975_11863" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
        <path d="M8 8H32V32H8V8Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_975_11863)">
        <path
          d="M18.7998 20L22.6998 23.9C22.8831 24.0834 22.9748 24.3167 22.9748 24.6C22.9748 24.8834 22.8831 25.1167 22.6998 25.3C22.5165 25.4834 22.2831 25.575 21.9998 25.575C21.7165 25.575 21.4831 25.4834 21.2998 25.3L16.6998 20.7C16.5998 20.6 16.529 20.4917 16.4873 20.375C16.4456 20.2584 16.4248 20.1334 16.4248 20C16.4248 19.8667 16.4456 19.7417 16.4873 19.625C16.529 19.5084 16.5998 19.4 16.6998 19.3L21.2998 14.7C21.4831 14.5167 21.7165 14.425 21.9998 14.425C22.2831 14.425 22.5165 14.5167 22.6998 14.7C22.8831 14.8834 22.9748 15.1167 22.9748 15.4C22.9748 15.6834 22.8831 15.9167 22.6998 16.1L18.7998 20Z"
          fill="#7C7C82"
        />
      </g>
    </Icon>
  );
}
