import { Box, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Rank from './Rank';
import { AppStoreState, useAppStore } from '../../store';
import storage from '../../utils/storage';
import { getUserLookupLevel } from '../../api/userLevels/userLevelApi';
import { UserLevelResponse } from '../../interfaces/components';

export default function Rankings() {
  const walletAddress = storage.get('walletAddress');
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const [userLookupLevels, setUserLookupLevels] = useState<UserLevelResponse[] | null>(null);

  const fetchUserLookupLevel = async () => {
    try {
      setLoading(true);
      const res = walletAddress && (await getUserLookupLevel(walletAddress));
      res && setUserLookupLevels(res);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching current user.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchUserLookupLevel();
  }, []);

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            width={['100%', '100%', '100%']}
            rowGap={['18px', '18px', '18px', '18px', '18px']}
            columnGap={['18px', '18px', '18px', '18px', '18px']}
            flexDirection={['column', 'column', 'row']}
            //   gap="24px"
            marginTop="24px"
            marginBottom="64px"
          >
            {userLookupLevels &&
              userLookupLevels?.length > 0 &&
              userLookupLevels
                .sort((a, b) => {
                  if (b?.current_user_level === true) return 1;
                  if (a?.current_user_level === true) return -1;
                  return 0;
                })
                .map((level, i) => <Rank key={i} userLevelData={level} />)}
          </Box>
        </>
      )}
    </>
  );
}
