import { Box, Text, Image, Button, Icon, useDisclosure } from '@chakra-ui/react';
import ProfilePicture from '../../assets/profilePicture.png';
import React from 'react';
import Trophy from '../../assets/trophy.png';
import VerifiedLogo from '../../Icons/Verified';
import VotesIcon from '../../Icons/Votes';
import ArrowForward from '../../Icons/Arrow';
import CrownIconGold from '../../Icons/CrownGold';
import CrownIconSilver from '../../Icons/CrownSilver';
import CrownIconBronze from '../../Icons/CrownBronze';
import { LeaderBoardDrawer } from '../modals/LeaderBoardDrawer';
import { TopNfts } from '../../interfaces/components';
// import { AllNftsResponseData } from '../../interfaces/components';

interface TopPlayersProps {
  topNfts: TopNfts[];
}

export default function TopPlayers(props: TopPlayersProps) {
  const { topNfts } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const icons = [CrownIconGold, CrownIconSilver, CrownIconBronze];

  return (
    <>
      <LeaderBoardDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {topNfts?.length > 0 && (
        <Box marginTop={['56px', '56px', '56px']}>
          <Text color=" #FFF" fontSize={['28px', '28px', '34px', '36px']} fontWeight="800">
            Top Nfts
          </Text>
          <Box display="flex" flexDirection={['column', 'column', 'row']} gap="24px" marginTop="24px">
            {topNfts.map((player: any, i: any) => (
              <Box
                borderRadius=" 26px"
                border="0.5px solid rgba(255, 255, 255, 0.10)"
                backgroundColor=" rgba(255, 255, 255, 0.10)"
                width={['100%', '100%', '33%']}
                padding="16px"
                key={i}
                position="relative"
              >
                <Box position="absolute" top="-30px" right=" 14px">
                  <Icon as={icons[i % icons.length]} />
                </Box>
                <Box borderRadius="12px" objectFit="cover" overflow="auto" width="-webkit-fill-available">
                  <Image
                    maxHeight="214px"
                    height="214px"
                    src={player?.display_image_url}
                    objectFit="cover"
                    width="inherit"
                    borderRadius="12px"
                  />
                </Box>

                <Box marginTop="28px" display="flex" gap="6px" alignItems="center">
                  {player?.owner?.profile_picture ? (
                    <Box width="40px" height="40px" borderRadius="50%" marginRight="4px">
                      <Image
                        src={player?.owner?.profile_picture}
                        objectFit="cover"
                        borderRadius="50%"
                        height="inherit"
                        width="inherit"
                      />
                    </Box>
                  ) : (
                    <Box width="40px" height="40px" borderRadius="50%">
                      <Image
                        src={ProfilePicture}
                        objectFit="cover"
                        height="inherit"
                        width="inherit"
                        borderRadius="50%"
                      />
                    </Box>
                  )}
                  {player?.owner?.twitter_username ? (
                    <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                      {player?.owner?.twitter_username}
                    </Text>
                  ) : (
                    <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                      Anonymous
                    </Text>
                  )}
                  <VerifiedLogo />
                </Box>
                <Box marginTop="14px" display="flex" gap="16px" alignItems="center">
                  <Box
                    padding="0px 24px"
                    borderRadius="15px"
                    background="rgba(255, 255, 255, 0.05)"
                    height="82px"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    style={{
                      aspectRatio: '1',
                    }}
                  >
                    <Text
                      color=" #FFF"
                      textAlign="center"
                      fontFamily="Montserrat"
                      fontSize="36px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="normal"
                    >
                      {i + 1}
                    </Text>
                  </Box>
                  <Box
                    padding="10px"
                    width="-webkit-fill-available"
                    display="flex"
                    height="82px"
                    flexDirection="column"
                    borderRadius="15px"
                    background=" rgba(255, 255, 255, 0.05)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="3px">
                      <VotesIcon color={'#FFFFFF'} />
                      <Text
                        color="#FFF"
                        textAlign="center"
                        fontFamily="Montserrat"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="normal"
                      >
                        Votes
                      </Text>
                    </Box>
                    <Text
                      color="#04D3FF"
                      textAlign="center"
                      fontFamily="Montserrat"
                      fontSize="24px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="normal"
                    >
                      {player?.votes && player?.votes}
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box marginTop="24px" display="flex" justifyContent={['center', 'center', 'flex-end']} position="relative">
            <Button
              display="flex"
              alignItems="center"
              // width={['80%', '50%', '302px', '302px', '302px']}
              borderRadius="18px"
              h={['60px', '60px', '60px']}
              _hover={{
                color: 'white',
                bg: '#00C22B',
              }}
              borderBottom="2px solid #00C22B"
              backgroundColor="#3AFF65"
              boxShadow="0px 2px 50px 0px #000"
              padding="0 16px"
              onClick={onOpen}
            >
              <Image
                src={Trophy}
                width="130px"
                height="130px"
                position="absolute"
                left={['-18px', '-20px', '-34px']}
                top={['-25px', '-25px', '-25px']}
              />
              <Box
                width={['230px', '230px', '250px']}
                flex="1"
                textAlign="center"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="8px"
              >
                <Text fontSize={['16px', '16px', '20px']} fontWeight="800" color="#0C0C0C">
                  LEADERBOARD
                </Text>
                <ArrowForward />
              </Box>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
