import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import CardIcon from '../../Icons/CardIcon';
import CardIcon2 from '../../Icons/CardIcon2';
import CardIcon3 from '../../Icons/CardIcon3';
import { AppStoreState, useAppStore } from '../../store';
import { useNavigate } from 'react-router-dom';

const working = [
  { id: 1, heading: 'Provide a post link', decription: 'Enter your tweet URL & we do the rest', icon: <CardIcon /> },
  { id: 2, heading: 'Get a snapshot', decription: 'We grab a clean screenshot of your tweet', icon: <CardIcon2 /> },
  { id: 2, heading: 'Your NFT', decription: 'Own your tweet & its history on the blockchain', icon: <CardIcon3 /> },
];

export default function Work() {
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const navigate = useNavigate();
  const onMintNftClick = () => {
    if (accessToken) {
      navigate('/mint-nft');
    } else {
      navigate('/login');
    }
  };
  return (
    <Box
      // margin="0px 16px"
      padding="12px 0px 64px 0px"
    >
      <Text color=" #FFF" fontSize={['28px', '28px', '34px', '36px']} fontWeight="800">
        How it works?
      </Text>
      <Box display="flex" flexDirection={['column', 'column', 'row']} gap="24px" marginTop="24px">
        {working.map((work, i) => (
          <Box
            borderRadius=" 26px"
            border="0.5px solid rgba(255, 255, 255, 0.10)"
            backgroundColor=" rgba(255, 255, 255, 0.10)"
            width={['100%', '100%', '33%']}
            padding="16px 16px 28px 16px"
            key={i}
          >
            <Box
              width="58px"
              height="58px"
              borderRadius="14px"
              border="0.5px solid rgba(255, 255, 255, 0.10)"
              backgroundColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {work?.icon}
            </Box>
            <Box marginTop="18px" display="flex" flexDirection="column" gap="18px">
              <Text color="#FFF" fontSize={['20px', '20px', '24px']} fontWeight="600">
                {work?.heading}
              </Text>
              <Text color="rgba(255, 255, 255, 0.50)" fontSize="16px" fontWeight="500">
                {work?.decription}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" marginTop="56px">
        <Button
          width="232px"
          borderRadius="15px"
          borderTop="1px solid #43BDD7"
          backgroundColor="#118BCF"
          padding="16px 32px"
          color=" #FFF"
          fontSize={['16px', '16px', '20px']}
          fontWeight=" 600"
          h="56px"
          _hover={{
            color: 'white',
            bg: '#43BDD7',
          }}
          onClick={onMintNftClick}
        >
          Mint your NFT
        </Button>
      </Box>
    </Box>
  );
}
