import {
  AllNftsResponse,
  FavoriteNftResponse,
  NftType,
  TweetScreenshotPayload,
  saveNFTResponse,
} from '../../interfaces/components';
import axios from '../axios';

export const saveNFT = async (transactionHash: `0x${string}`) => {
  try {
    const endPoint = `/api/nfts/save-nft/`;
    const res = await axios.post<saveNFTResponse>(
      endPoint,
      { transactionHash },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const addTweetScreenShot = async (data: TweetScreenshotPayload) => {
  try {
    const endPoint = `/api/tweets/screenshot/`;
    const res = await axios.post<TweetScreenshotPayload>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const getAllNfts = async (page: number, recordsPerPage: number): Promise<AllNftsResponse> => {
  try {
    const endPoint = `/api/nfts/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint, { params: { collection: 'mytoken-10277' } });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMyNfts = async (
  page: number,
  recordsPerPage: number,
  walletAddress: string,
): Promise<AllNftsResponse> => {
  try {
    const endPoint = `/api/nfts/?page=${page}&per_page=${recordsPerPage}`;
    const res = await axios.get(endPoint, { params: { address: walletAddress } });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getMyFavoriteNfts = async (): Promise<FavoriteNftResponse[]> => {
  try {
    const endPoint = `/api/nfts/favorites/`;
    const res = await axios.get(endPoint);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNftDetails = async (tokenId: string): Promise<NftType> => {
  try {
    const endPoint = `/api/nfts/details`;
    const res = await axios.get(endPoint, {
      params: {
        // address: walletAddress,
        token_id: tokenId,
      },
    });
    if (!res?.data) throw 'Something went wrong GetUser';
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addNftToFavorite = async (nftId: string) => {
  try {
    const endPoint = `api/nfts/${nftId}/like/`;
    const res = await axios.post<any>(
      endPoint,
      // , {
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      // }
    );
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
