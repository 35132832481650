import React from 'react';
import VerifiedLogo from '../../Icons/Verified';
import EthereumLogo from '../../Icons/Ethereum';
import { AllNftsResponse, AllNftsResponseData, FavoriteNftResponse } from '../../interfaces/components';
import { Box, Image, Text, Divider, Button, useToast } from '@chakra-ui/react';
import { addNftToFavorite } from '../../api/nfts/apis';
import HeartIcon from '../../Icons/HeartcIcon';

interface NftProps {
  favNfts?: FavoriteNftResponse[];
  myNfts?: AllNftsResponse;
  removeFromFav?: boolean;
  tabIndex: number;
  onNftClick: (nft: AllNftsResponseData | FavoriteNftResponse) => void;
  setRemoveFromFav?: (remove: boolean) => void;
}

export default function Nft(props: NftProps) {
  const { myNfts, onNftClick, tabIndex, favNfts, setRemoveFromFav, removeFromFav } = props;
  const toast = useToast();

  const unFavoriteNft = async (id: string) => {
    try {
      const res = await addNftToFavorite(id);
      if (res?.message === 'NFT unliked') {
        setRemoveFromFav && setRemoveFromFav(!removeFromFav);
        toast({
          title: 'Nft removed from favorites successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while removing nft from favorite.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {tabIndex === 0 ? (
        <>
          {' '}
          {myNfts &&
            myNfts?.data?.length > 0 &&
            myNfts?.data.map(
              (nft, i) =>
                nft?.display_image_url && (
                  <Box
                    key={i}
                    borderRadius="26px"
                    border="1px solid rgba(255, 255, 255, 0.10)"
                    backgroundColor="rgba(255, 255, 255, 0.10)"
                    width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                    paddingTop="30%"
                    position="relative"
                    padding="16px 16px 24px 16px"
                    cursor="pointer"
                    onClick={() => onNftClick(nft)}
                  >
                    <Image
                      src={nft?.display_image_url}
                      width={['inherit', 'inherit', '100%', '100%', '100%']}
                      height="214px"
                      maxHeight="214px"
                      objectFit="cover"
                      cursor="pointer"
                      borderRadius="12px"
                    />
                    <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                      {nft?.name}
                    </Text>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop="10px"
                      marginBottom="8px"
                    >
                      <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                        Creator
                      </Text>
                      {nft?.owner?.username ? (
                        <Box display="flex" justifyContent="center" gap="6px">
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            {nft?.owner?.username}
                          </Text>
                          <VerifiedLogo />
                        </Box>
                      ) : (
                        <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                          Anonymous
                        </Text>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                        <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                          Network
                        </Text>
                        <Box
                          borderRadius="19px"
                          background="rgba(255, 255, 255, 0.10)"
                          padding="4px 4px 4px 10px"
                          display="flex"
                          gap="6px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            Ethereum
                          </Text>
                          <EthereumLogo boxSize={4} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ),
            )}
        </>
      ) : (
        <>
          {favNfts &&
            favNfts?.length > 0 &&
            favNfts?.map(
              (nft, i) =>
                nft?.display_image_url && (
                  <Box
                    key={i}
                    borderRadius="26px"
                    border="1px solid rgba(255, 255, 255, 0.10)"
                    backgroundColor="rgba(255, 255, 255, 0.10)"
                    width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                    paddingTop="30%"
                    position="relative"
                    padding="16px 16px 24px 16px"
                    cursor="pointer"
                    // onClick={() => onNftClick(nft)}
                  >
                    <Image
                      src={nft?.display_image_url}
                      width={['inherit', 'inherit', '100%', '100%', '100%']}
                      height="214px"
                      maxHeight="214px"
                      objectFit="cover"
                      cursor="pointer"
                      borderRadius="12px"
                    />
                    <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                      {nft?.name}
                    </Text>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop="10px"
                      marginBottom="8px"
                    >
                      <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                        Creator
                      </Text>
                      {nft?.owner?.username ? (
                        <Box display="flex" justifyContent="center" gap="6px">
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            {nft?.owner?.username}
                          </Text>
                          <VerifiedLogo />
                        </Box>
                      ) : (
                        <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                          Anonymous
                        </Text>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                        <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                          Network
                        </Text>
                        <Box
                          borderRadius="19px"
                          background="rgba(255, 255, 255, 0.10)"
                          padding="4px 4px 4px 10px"
                          display="flex"
                          gap="6px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            Ethereum
                          </Text>
                          <EthereumLogo boxSize={4} />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" marginTop="18px">
                      <Button
                        width="100%"
                        borderRadius="15px"
                        backgroundColor="#414141"
                        padding="16px 32px"
                        color="#FFF"
                        fontSize="14px"
                        fontWeight="500"
                        h="44px"
                        _hover={{
                          color: 'white',
                          bg: 'rgba(255, 255, 255, 0.20)',
                        }}
                        leftIcon={<HeartIcon />}
                        onClick={() => unFavoriteNft(nft?.identifier)}
                      >
                        Favorited
                      </Button>
                    </Box>
                  </Box>
                ),
            )}
        </>
      )}
    </>
  );
}
