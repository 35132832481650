import { Box, ModalBody, ModalContent, Text, Image, Button, ModalOverlay, useToast } from '@chakra-ui/react';
import React from 'react';
import { AllNftsResponseData } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { addNftToCompetition } from '../../api/competitions/apis';

interface SubmitConfirmationModalProps {
  submitClicked?: boolean;
  setSubmitClicked?: (submitted: boolean) => void;
  onBrowseModalClose: () => void;

  heading: string;
}

export const SubmitConfirmationModal = (props: SubmitConfirmationModalProps) => {
  const toast = useToast();
  const { setSubmitClicked, onBrowseModalClose, heading } = props;
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const setAddNftToCompetitionStatus = useAppStore((state: AppStoreState) => state.setAddNftToCompetitionStatus);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const selectedNft = useAppStore((state: AppStoreState) => state.selectedNft);
  const mintNowView = useAppStore((state: AppStoreState) => state.mintNowView);

  const onSubmitClick = async (nft: AllNftsResponseData) => {
    if (selectedCompetitionId && nft) {
      const data = {
        token_id: selectedNft?.identifier,
      };
      try {
        const res = await addNftToCompetition(selectedCompetitionId, data);
        if (res?.message === 'NFT is already in the competition' || res?.message === 'NFT added to competition') {
          setSubmitClicked && setSubmitClicked(true);
          setAddNftToCompetitionStatus('success');
          onBrowseModalClose();
        }
        setSelectedNft(null);
      } catch (error: any) {
        toast({
          title: 'Something went wrong while adding nft to competition.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setSubmitClicked && setSubmitClicked(true);
        setAddNftToCompetitionStatus('failure');
        onBrowseModalClose();
        setSelectedNft(null);
      }
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalContent className="submit-confirmation-modal" bg="transparent">
        <ModalBody pb={6} className="modal-body">
          <Box display="flex" justifyContent="center">
            <Box width="500px" height="fit-content" display="flex" flexDirection="column" alignItems="center">
              <Text
                color="#FFF"
                fontSize={['20px', '20px', '28px']}
                fontWeight="700"
                textAlign="center"
                fontFamily="Montserrat"
              >
                {heading}
              </Text>
              <Image
                width={['287px', '324px', '100%']}
                src={selectedNft?.display_image_url}
                marginTop="36px"
                zIndex={4}
                borderRadius="12px"
              />
              <Box
                padding="1px"
                backgroundColor="#1F1F1F"
                marginTop={['-20px', '-20px', '-64px']}
                zIndex={3}
                style={{
                  borderRadius: '24px',
                  backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: ' content-box, border-box',
                }}
              >
                <Box padding={['36px 20px', '46px 26px', '100px 36px 36px 36px']}>
                  {mintNowView ? (
                    <>
                      <Button
                        width={['271px', '351px', '474px', '474px']}
                        borderRadius="15px"
                        borderTop="1px solid #43BDD7"
                        backgroundColor="#118BCF"
                        padding="16px 32px"
                        color="#FFF"
                        fontSize="20px"
                        fontWeight="600"
                        height="52px"
                        _hover={{
                          color: 'white',
                          bg: '#43BDD7',
                        }}
                        onClick={() => onSubmitClick(selectedNft)}
                        cursor="pointer"
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    <Box display="flex" gap="12px">
                      <Button
                        border=" 0.5px solid rgba(255, 255, 255, 0.20)"
                        background=" #222"
                        width={['136px', '176px', '231px', '231px']}
                        borderRadius="15px"
                        padding="16px 32px"
                        color="#FFF"
                        fontSize="20px"
                        fontWeight="600"
                        height="52px"
                        _hover={{
                          color: 'white',
                          bg: 'rgba(255, 255, 255, 0.20)',
                        }}
                        cursor="pointer"
                        onClick={() => setSelectedNft(null)}
                      >
                        Back
                      </Button>
                      <Button
                        width={['136px', '176px', '231px', '231px']}
                        borderRadius="15px"
                        borderTop="1px solid #43BDD7"
                        backgroundColor="#118BCF"
                        padding="16px 32px"
                        color="#FFF"
                        fontSize="20px"
                        fontWeight="600"
                        height="52px"
                        _hover={{
                          color: 'white',
                          bg: '#43BDD7',
                        }}
                        onClick={() => onSubmitClick(selectedNft)}
                        cursor="pointer"
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </>
  );
};
