import { Button } from '@chakra-ui/react';
import React from 'react';

function base64URLEncode(str: ArrayBuffer): string {
  const uint8Array = new Uint8Array(str);
  let binary = '';
  for (let i = 0; i < uint8Array.length; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }
  return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

async function sha256(plain: any) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return crypto.subtle.digest('SHA-256', data);
}

async function generateCodeChallenge(code_verifier: any) {
  const hashed = await sha256(code_verifier);
  return base64URLEncode(hashed);
}

async function loginWithXpad() {
  const code_verifier = base64URLEncode(crypto.getRandomValues(new Uint8Array(32)));
  const code_challenge = await generateCodeChallenge(code_verifier);

  // Save code_verifier in session storage to use later
  sessionStorage.setItem('code_verifier', code_verifier);

  // Redirect to Xpad OAuth2 authorization endpoint
  const xpadAuthUrl = `https://api.xpad-extension.baboons.tech/o/authorize/?client_id=V7vVjVevRwJBi5rJWbmOu51GUjfNpb5vU0n8hih8&response_type=code&redirect_uri=https://layerx.baboons.tech/auth-callback/&code_challenge=${code_challenge}&code_challenge_method=S256`;

  window.location.href = xpadAuthUrl;
}
export default function LoginWithXpadButton() {
  return <Button onClick={loginWithXpad}>Log in with Xpad</Button>;
}
