import { Box } from '@chakra-ui/react';
import React from 'react';
import NftDetails from './NftDetails';
import FeaturedNft from '../home/FeaturedNft';

export default function NftOverViewMain() {
  return (
    <Box
      zIndex={2}
      flexDirection="column"
      maxHeight="100%"
      overflow="auto"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for WebKit browsers
        },
        '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
        'scrollbar-width': 'none', // Hide scrollbar for Firefox
      }}
      marginBottom="30px"
      marginTop="20px"
      maxWidth="1400px"
    >
      <NftDetails />
      <Box>
        <FeaturedNft />
      </Box>
    </Box>
  );
}
