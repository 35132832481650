import { Box, Button, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { NavbarOption, UnauthorizedHeaderProps } from '../../interfaces/components';
import MobileMenuIcon from '../../Icons/MobileMenuIcon';

export default function UnauthorizedHeader({ navbarOptions, openDrawer }: UnauthorizedHeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuItemClick = (navbarOptionItem: NavbarOption) => {
    navigate(navbarOptionItem?.href);
  };

  function base64URLEncode(str: ArrayBuffer): string {
    const uint8Array = new Uint8Array(str);
    let binary = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  async function sha256(plain: any) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return crypto.subtle.digest('SHA-256', data);
  }

  async function generateCodeChallenge(code_verifier: any) {
    const hashed = await sha256(code_verifier);
    return base64URLEncode(hashed);
  }

  async function loginWithXpad() {
    const code_verifier = base64URLEncode(crypto.getRandomValues(new Uint8Array(32)));
    const code_challenge = await generateCodeChallenge(code_verifier);
    // Save code_verifier in session storage to use later
    sessionStorage.setItem('code_verifier', code_verifier);
    // Redirect to Xpad OAuth2 authorization endpoint
    const xpadAuthUrl = `https://webapp.xpad.baboons.tech/authorize/?client_id=l32Vl8gd99okNaYgJ5GnloS2SbKSsHoNlAgPvdlF&response_type=code&redirect_uri=https://layerx.baboons.tech/auth-callback/&code_challenge=${code_challenge}&code_challenge_method=S256`;
    window.location.href = xpadAuthUrl;
  }

  return (
    <>
      <Box width="100%" h={['54px', '54px', '54px', '65px', '65px']} borderBottom="1px solid rgba(255, 255, 255, 0.10)">
        <Box
          margin="0 auto"
          width="inherit"
          height="inherit"
          padding={['14px 12px', '14px 12px', '14px 12px', '20px 24px']}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          maxWidth={['unset', 'unset', '1400px']}
        >
          <Box
            display={['none', 'none', 'block', 'block']}
            cursor="pointer"
            onClick={() => {
              // navigate('/');
            }}
          >
            <Image src={Logo} height={['20px', '20px', '20px', '24x', '24px']} />
          </Box>

          <Box
            display={['block', 'block', 'none', 'none']}
            onClick={() => {
              navigate('/');
            }}
          >
            <Image src={Logo} height={['30px', '30px', '30px', '30px', '40px']} />
          </Box>

          <Box display={['none', 'none', 'flex']} gap="24px">
            {navbarOptions.map((navbarOption: NavbarOption, i) => (
              <Box key={i} onClick={() => onMenuItemClick(navbarOption)}>
                <Text
                  color={location.pathname === navbarOption?.href ? '#FFF' : 'rgba(255, 255, 255, 0.50)'}
                  fontSize="14px"
                  fontWeight="600"
                  cursor="pointer"
                >
                  {navbarOption?.text}
                </Text>
              </Box>
            ))}
          </Box>

          <Box display={['none', 'none', 'flex']} gap="10px">
            <Button
              width="113px"
              borderRadius="15px"
              backgroundColor="#118BCF"
              padding="16px 32px"
              color=" #FFF"
              fontSize="16px"
              fontWeight=" 600"
              h="44px"
              _hover={{
                color: 'white',
                bg: '#43BDD7',
              }}
              onClick={() => loginWithXpad()}
            >
              Sign up
            </Button>
          </Box>
          <Box onClick={openDrawer} display={['unset', 'unset', 'none', 'none', 'none']}>
            <MobileMenuIcon boxSize={8} cursor="pointer" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
