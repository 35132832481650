import { Box, Text, Image, Divider, useToast, Spinner } from '@chakra-ui/react';
import EthereumLogo from '../../Icons/Ethereum';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllNftsResponse, AllNftsResponseData } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { getAllNfts } from '../../api/nfts/apis';
import VerifiedLogo from '../../Icons/Verified';
import Pagination from '../common/Pagination';

interface FeaturedNftProps {
  fetchAgain?: boolean;
  setFetchAgain?: (fetch: boolean) => void;
}

export default function AllNfts(props: FeaturedNftProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const setRerender = useAppStore((state: AppStoreState) => state.setRerender);
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const { fetchAgain, setFetchAgain } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const [allNfts, setAllNfts] = useState<AllNftsResponse>();

  const fetchAllNfts = async (page: number, recordsPerPage: number) => {
    try {
      setLoading(true);
      const res = await getAllNfts(page, recordsPerPage);
      setAllNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllNfts(1, 9);
  }, []);

  const onNftClick = (nft: AllNftsResponseData) => {
    setRerender(!reRender);
    if (setFetchAgain) {
      setFetchAgain(!fetchAgain);
    }
    navigate(`/nfts/${nft?.identifier}`);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (allNfts?.total_pages && currentPage < allNfts?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    fetchAllNfts(currentPage, 9);
  }, [currentPage]);

  const onPaginationitemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  console.log('allNfts', allNfts);

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" height="100px" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <Box paddingTop="12px 0px 64px 0px" marginTop="64px">
          <Text color=" #FFF" fontSize={['28px', '28px', '36px']} fontWeight="800" marginBottom="24px">
            NFT Overview
          </Text>

          <Box
            display="flex"
            flexWrap="wrap"
            rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
            columnGap={['12px', '12px', '12px', '32px', '24px']} // Horizontal gap
          >
            {allNfts &&
              allNfts?.data?.length > 0 &&
              allNfts?.data
                ?.filter((nft) => nft.display_image_url)
                .map(
                  (nft, i) =>
                    nft?.display_image_url && (
                      <Box
                        key={i}
                        borderRadius="26px"
                        border="1px solid rgba(255, 255, 255, 0.10)"
                        width={['-webkit-fill-available', '-webkit-fill-available', '49%', '48%', '32%']}
                        onClick={() => onNftClick(nft)}
                        position="relative"
                        padding="16px 16px 24px 16px"
                        cursor="pointer"
                        backgroundColor={'rgba(255, 255, 255, 0.10)'}
                      >
                        <Image
                          width={['inherit', 'inherit', '100%', '100%', '100%']}
                          cursor="pointer"
                          src={nft?.display_image_url}
                          maxHeight="214px"
                          height="214px"
                          objectFit="cover"
                          borderRadius="12px"
                        />
                        <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                          {nft?.name}
                        </Text>
                        <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginTop="10px"
                          marginBottom="8px"
                        >
                          <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                            Creator
                          </Text>
                          {nft?.owner?.username ? (
                            <Box display="flex" justifyContent="center" gap="6px">
                              <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                                {nft?.owner?.username}
                              </Text>
                              <VerifiedLogo />
                            </Box>
                          ) : (
                            <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                              Anonymous
                            </Text>
                          )}
                        </Box>
                        <Box display="flex" flexDirection="column" gap="5px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                              Network
                            </Text>
                            <Box
                              borderRadius="19px"
                              background="rgba(255, 255, 255, 0.10)"
                              display="flex"
                              gap="6px"
                              justifyContent="center"
                              alignItems="center"
                              padding="4px 4px 4px 10px"
                            >
                              <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                                Ethereum
                              </Text>
                              <EthereumLogo boxSize={6} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ),
                )}
          </Box>
          {allNfts?.total_pages && (
            <Pagination
              handlePreviousPage={handlePrevPage}
              totalPages={allNfts?.total_pages}
              currentPage={currentPage}
              onPaginationitemClick={onPaginationitemClick}
              handleNextPage={handleNextPage}
            />
          )}
          {/* {allNfts?.total_pages && (
            <Box display="flex" alignItems="center" justifyContent={['center', 'center', 'flex-end']} marginTop="56px">
              <Box display="flex" alignItems="center" gap="8px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={handlePrevPage}
                >
                  <ArrowLeftIcon />
                </Box>
                {Array(Number(allNfts?.total_pages))
                  .fill(0)
                  .map((e, i) => (
                    <Box
                      key={e}
                      height="40px"
                      width="40px"
                      display="flex"
                      justifyContent="center"
                      borderRadius="50%"
                      alignItems="center"
                      backgroundColor={i + 1 === currentPage ? '#118BCF' : 'unset'}
                      cursor="pointer"
                      onClick={() => onPaginationitemClick(i + 1)}
                    >
                      <Text
                        color={i + 1 === currentPage ? '#FFF' : '#7C7C82'}
                        textAlign="center"
                        fontFamily="Inter"
                        fontSize=" 16px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="20px"
                      >
                        {i + 1}
                      </Text>
                    </Box>
                  ))}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={handleNextPage}
                >
                  <ArrowRightIcon />
                </Box>
              </Box>
            </Box>
          )} */}
        </Box>
      )}
    </>
  );
}
