import { Box, Divider, Image, Text } from '@chakra-ui/react';
import TwitterLogo from '../../assets/twitter.png';
import SocialIcon from '../../assets/socalIcon.png';
import React from 'react';
import LayerXLogoIcon from '../../Icons/LayerXLogoIcon';

export default function Footer() {
  return (
    <Box
      borderRadius="15px"
      background="#242424"
      padding="16px"
      width="100%"
      border="1px solid rgba(255, 255, 255, 0.10)"
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <LayerXLogoIcon />
        </Box>

        <Box display="flex" gap="16px">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <Image src={TwitterLogo} height={['20px', '20px', '20px', '24x', '24px']} />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <Image src={SocialIcon} height={['20px', '20px', '20px', '24x', '24px']} />
          </a>
        </Box>
      </Box>
      <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginTop="18px" marginBottom="16px" />
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent={['flex-start', 'flex-start', 'center', 'center', 'center']}
        alignItems={['flex-start', 'flex-start', 'center', 'center', 'center']}
        gap="24px"
      >
        <a href="/resources">
          <Text color="rgba(255, 255, 255, 0.50)" fontSize=" 14px" fontWeight="600">
            Resources
          </Text>
        </a>
        <a href="/privacyPolicy">
          <Text color=" rgba(255, 255, 255, 0.50)" fontSize=" 14px" fontWeight="600">
            Privacy Policy
          </Text>
        </a>
        <a href="/termsAndCondition">
          <Text color=" rgba(255, 255, 255, 0.50)" fontSize=" 14px" fontWeight="600">
            Terms and Conditions
          </Text>
        </a>
      </Box>
      <Box marginTop="36px" display="flex" justifyContent="center" alignItems="center">
        <Text color="rgba(255, 255, 255, 0.30)" fontSize="12px" fontWeight="500">
          © Layer X, 2024. All right reseverved.
        </Text>
      </Box>
    </Box>
  );
}
