import { Box, Text, useToast, Spinner, Tab, TabList, Tabs } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllNftsResponse, AllNftsResponseData, FavoriteNftResponse } from '../../interfaces/components';
import { getMyFavoriteNfts, getMyNfts } from '../../api/nfts/apis';
import { currentUserApi } from '../../api/api';

import NFTFilled from '../../Icons/NFTFilled';
import HeartFilledIcon from '../../Icons/HeartFilledIcon';
import Nft from './Nft';
import Pagination from '../common/Pagination';

interface CustomTabProps {
  isActive: boolean;
  children: ReactNode;
}

export default function MyNfts() {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [myNfts, setMyNfts] = useState<AllNftsResponse>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [tabIndex, setTabIndex] = useState(0);
  const [favNfts, setFavNfts] = useState<FavoriteNftResponse[]>();
  const [removeFromFav, setRemoveFromFav] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const fetchData = async (page: number, recordsPerPage: number, walletAddress: string) => {
    try {
      setLoading(true);
      const res = walletAddress && (await getMyNfts(page, recordsPerPage, walletAddress));
      res && setMyNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFTs.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchMyFavoriteNfts = async () => {
    try {
      setLoading(true);
      const res = await getMyFavoriteNfts();
      res && setFavNfts(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching favorite NFTs.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      handleMyNftsClick();
    } else {
      handleFavoriteNftsClick();
    }
  }, [tabIndex, removeFromFav]);

  useEffect(() => {
    walletAddress && fetchData(1, 10, walletAddress);
  }, [walletAddress]);

  const onNftClick = (nft: AllNftsResponseData | FavoriteNftResponse) => {
    navigate(`/nfts/${nft?.identifier}`);
  };

  const fetchCurrentUser = async () => {
    try {
      setLoading(true);
      const res = await currentUserApi();
      setWalletAddress(res?.data?.wallet_address);
    } catch (error: unknown) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching current user.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    console.log('myNfts', myNfts);
  }, [myNfts]);

  useEffect(() => {
    console.log('favNfts', favNfts);
  }, [favNfts]);

  useEffect(() => {
    console.log('loading', loading);
  }, [loading]);

  const handleFavoriteNftsClick = () => {
    setTabIndex(1);
    fetchMyFavoriteNfts();
  };

  const handleMyNftsClick = () => {
    walletAddress && fetchData(1, 10, walletAddress);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (myNfts?.total_pages && currentPage < myNfts?.total_pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPaginationItemClick = (pageToFetch: number) => {
    setCurrentPage(pageToFetch);
  };

  const CustomTab: React.FC<CustomTabProps> = ({ isActive, children }) => {
    return (
      <Tab
        width="174px"
        borderRadius="15px"
        bg={isActive ? '#353535' : 'transparent'}
        display="flex"
        alignItems="center"
        gap="1"
        color={isActive ? '#04D3FF' : '#909090'}
        _hover={{
          bg: '#353535',
          color: '#04D3FF',
        }}
      >
        {children}
      </Tab>
    );
  };

  return (
    <Box margin="0px 0px 64px 0px" display="flex" flexDirection="column" gap="16px">
      <Tabs
        variant="unstyled"
        borderRadius="20px"
        border="0.5px solid rgba(255, 255, 255, 0.20)"
        background="#222"
        padding="10px"
        width="fit-content"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList gap="2">
          <CustomTab isActive={tabIndex === 0}>
            <NFTFilled fill="currentcolor" boxSize={[6]} />
            <Text color="currentcolor" fontSize="20px" fontWeight="600">
              MY NFTs
            </Text>
          </CustomTab>

          <CustomTab isActive={tabIndex === 1}>
            <HeartFilledIcon fill="currentcolor" boxSize={[6]} />
            <Text color="currentcolor" fontSize="20px" fontWeight="600">
              Favorites
            </Text>
          </CustomTab>
        </TabList>
      </Tabs>

      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            // justifyContent="space-between"
            rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
            columnGap={['12px', '12px', '12px', '12px', '12px']} // Horizontal gap
          >
            {tabIndex == 0 ? (
              <>
                {myNfts && myNfts?.data?.length > 0 ? (
                  <Nft myNfts={myNfts} onNftClick={onNftClick} tabIndex={tabIndex} />
                ) : null}
              </>
            ) : (
              <>
                {favNfts && favNfts?.length > 0 ? (
                  <Nft
                    favNfts={favNfts}
                    onNftClick={onNftClick}
                    tabIndex={tabIndex}
                    setRemoveFromFav={setRemoveFromFav}
                    removeFromFav={removeFromFav}
                  />
                ) : null}
              </>
            )}
          </Box>
          {tabIndex == 0 && myNfts?.data?.length ? (
            <Pagination
              handlePreviousPage={handlePrevPage}
              totalPages={myNfts?.total_pages}
              currentPage={currentPage}
              onPaginationitemClick={onPaginationItemClick}
              handleNextPage={handleNextPage}
            />
          ) : null}
        </>
      )}
    </Box>
  );
}
