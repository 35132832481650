import { Box, Spinner, Image, Text, Button } from '@chakra-ui/react';
import Logo from '../../assets/logo.png';
import React from 'react';
import { AppStoreState, useAppStore } from '../../store';
import TwitterIcon from '../../Icons/Twitter';
import { twitterLogin } from '../../api/userApiCalls/user';
export default function Login() {
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);

  const twitterLog = async () => {
    setLoading(true);
    try {
      const res = await twitterLogin();
      res?.url && setLoading(false);
      window.location.href = res.url;
    } catch (e) {
      setLoading(false);
    }
  };

  function base64URLEncode(str: ArrayBuffer): string {
    const uint8Array = new Uint8Array(str);
    let binary = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  async function sha256(plain: any) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return crypto.subtle.digest('SHA-256', data);
  }

  async function generateCodeChallenge(code_verifier: any) {
    const hashed = await sha256(code_verifier);
    return base64URLEncode(hashed);
  }

  async function loginWithXpad() {
    const code_verifier = base64URLEncode(crypto.getRandomValues(new Uint8Array(32)));
    const code_challenge = await generateCodeChallenge(code_verifier);

    // Save code_verifier in session storage to use later
    sessionStorage.setItem('code_verifier', code_verifier);

    // Redirect to Xpad OAuth2 authorization endpoint
    const xpadAuthUrl = `https://webapp.xpad.baboons.tech/authorize/?client_id=YB1CjmWCgjMsiIpT9ihIV27Lz8aXzfy7hPCMMXE2&response_type=code&redirect_uri=https://layerx.baboons.tech/auth-callback/&code_challenge=${code_challenge}&code_challenge_method=S256`;

    window.location.href = xpadAuthUrl;
  }

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" height="100%">
          <Spinner />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Box
            padding="1px"
            backgroundColor="#1F1F1F"
            zIndex={2}
            width="416px"
            style={{
              borderRadius: '24px',
              backgroundImage: 'linear-gradient(#1F1F1F, #1F1F1F), linear-gradient(#5F5F5F4D, #4949494D)',
              backgroundOrigin: 'border-box',
              backgroundClip: ' content-box, border-box',
            }}
          >
            <Box
              padding={['20px 20px', '36px', '36px']}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Image src={Logo} w="176px" height="42px" />
              <Text fontWeight={500} fontSize="18px" color="white" marginTop="24px">
                Log in with your Twitter to continue
              </Text>
              <Button
                marginTop="24px"
                leftIcon={<TwitterIcon />}
                width="100%"
                borderRadius=" 15px"
                borderTop="1px solid #43BDD7"
                backgroundColor="#118BCF"
                padding="16px 32px"
                color=" #FFF"
                fontSize=" 20px"
                fontWeight=" 600"
                h="48px"
                _hover={{
                  color: 'white',
                  bg: '#43BDD7',
                }}
                onClick={() => twitterLog()}
              >
                Login with twitter
              </Button>

              <Text fontWeight={500} fontSize="14px" color="rgba(255, 255, 255, 0.50)" marginTop="24px">
                Or
              </Text>
              <Button
                marginTop="24px"
                leftIcon={<TwitterIcon />}
                width="100%"
                borderRadius=" 15px"
                borderTop="1px solid #43BDD7"
                backgroundColor="#118BCF"
                padding="16px 32px"
                color=" #FFF"
                fontSize=" 20px"
                fontWeight=" 600"
                h="48px"
                _hover={{
                  color: 'white',
                  bg: '#43BDD7',
                }}
                onClick={() => loginWithXpad()}
              >
                Login with XPAD
              </Button>
              <Text fontWeight={500} fontSize="14px" color="rgba(255, 255, 255, 0.50)" marginTop="24px">
                By logging in you agree to our <span style={{ color: '#04D3FF' }}>privacy policy</span> & <br />{' '}
                <div style={{ textAlign: 'center' }}>
                  <span style={{ color: '#04D3FF' }}>terms of use</span>
                </div>
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
