import { Box, Divider, Image, Text, Button, useToast, Spinner } from '@chakra-ui/react';
import React from 'react';
import EthereumLogo from '../../Icons/Ethereum';
import { AllNftsResponseData } from '../../interfaces/components';
import VotesIcon from '../../Icons/Votes';
import {
  addVoteToCompetingNfts,
  getCompetitionDetails,
  removeVoteFromCompetingNfts,
} from '../../api/competitions/apis';
import { AppStoreState, useAppStore } from '../../store';
import VerifiedLogo from '../../Icons/Verified';
import { isBefore, parseISO, isAfter } from 'date-fns';

interface NftListViewProps {
  allNfts: AllNftsResponseData[] | undefined;
  showButton?: boolean;
  onBrowseModalClose?: () => void;
}

export default function NftListView(props: NftListViewProps) {
  const { allNfts, showButton } = props;
  const toast = useToast();
  const accessToken = useAppStore((state: AppStoreState) => state.accessToken);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const setCompetitionDetails = useAppStore((state: AppStoreState) => state.setCompetitionDetails);
  const selectedCompetitionId = useAppStore((state: AppStoreState) => state.selectedCompetitionId);
  const setSelectedNft = useAppStore((state: AppStoreState) => state.setSelectedNft);
  const competitionDetails = useAppStore((state: AppStoreState) => state.competitionDetails);

  const onSelectClick = (nft: AllNftsResponseData) => {
    setSelectedNft && setSelectedNft(nft);
  };

  const onVoteNowClick = async (nft: AllNftsResponseData) => {
    const data = {
      token_id: nft?.identifier,
    };
    if (selectedCompetitionId) {
      if (!nft?.has_logged_in_user_voted) {
        try {
          const res = await addVoteToCompetingNfts(selectedCompetitionId, data);
          res?.message && fetchCompetitionById(selectedCompetitionId);
          toast({
            title: 'Vote added',
            description: 'Vote added to competition',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } catch (error: any) {
          toast({
            title: 'Error',
            description: 'Something went wrong while adding vote to competition.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        try {
          const res =
            selectedCompetitionId && ((await removeVoteFromCompetingNfts(selectedCompetitionId, data)) as any);
          res?.message && fetchCompetitionById(selectedCompetitionId);
          toast({
            title: 'Vote removed',
            description: 'Vote removed from competition',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } catch (error: any) {
          toast({
            title: 'Error',
            description: 'Something went wrong while removing vote to competition.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  };

  const fetchCompetitionById = async (competitionId: number) => {
    try {
      setLoading(true);
      const res = await getCompetitionDetails(competitionId);
      setCompetitionDetails(res);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching Competition Details.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  console.log('nft', allNfts);

  console.log('competitionDetails from Nft List view', competitionDetails);

  return (
    <>
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            rowGap={['20px', '20px', '24px', '24px', '24px']} // Vertical gap
            columnGap={['12px', '12px', '12px', '32px', '24px']} // Horizontal gap
          >
            {allNfts &&
              allNfts
                ?.filter((nft) => nft.display_image_url)
                .map((nft, i) => (
                  <Box
                    key={i}
                    borderRadius="26px"
                    border="1px solid rgba(255, 255, 255, 0.10)"
                    width={['-webkit-fill-available', '-webkit-fill-available', '49%', '48%', '32%']}
                    position="relative"
                    padding="16px 16px 24px 16px"
                    cursor="pointer"
                    backgroundColor={'rgba(255, 255, 255, 0.10)'}
                  >
                    <Image
                      width={['inherit', 'inherit', '100%', '100%', '100%']}
                      cursor="pointer"
                      src={nft?.display_image_url}
                      maxHeight="214px"
                      height="214px"
                      objectFit="cover"
                      borderRadius="12px"
                    />
                    <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                      {nft?.name}
                    </Text>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginTop="10px"
                      marginBottom="8px"
                    >
                      <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                        Creator
                      </Text>
                      {nft?.owner?.username ? (
                        <Box display="flex" justifyContent="center" gap="6px">
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            {nft?.owner?.username}
                          </Text>
                          <VerifiedLogo />
                        </Box>
                      ) : (
                        <Text color=" rgba(255, 255, 255, 0.10)" fontSize="14px" fontWeight="600">
                          Anonymous
                        </Text>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Text color="rgba(255, 255, 255, 0.50)" fontSize="14px">
                          Network
                        </Text>
                        <Box
                          borderRadius="19px"
                          background="rgba(255, 255, 255, 0.10)"
                          display="flex"
                          gap="6px"
                          justifyContent="center"
                          alignItems="center"
                          padding="4px 4px 4px 10px"
                        >
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            Ethereum
                          </Text>
                          <EthereumLogo boxSize={6} />
                        </Box>
                      </Box>
                    </Box>
                    {showButton &&
                      competitionDetails &&
                      isAfter(new Date(), parseISO(competitionDetails?.voting_starts)) &&
                      isBefore(new Date(), parseISO(competitionDetails?.voting_ends)) && (
                        <>
                          <Box display={[accessToken ? 'block' : 'none']} marginTop="10px">
                            <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="18px" />
                            <Button
                              width="100%"
                              borderRadius="15px"
                              borderTop={
                                !nft?.has_logged_in_user_voted ? '1px solid #04D3FF' : 'rgba(255, 255, 255, 0.20)'
                              }
                              padding="16px 32px"
                              color=" #FFF"
                              backgroundColor={!nft?.has_logged_in_user_voted ? '#118BCF' : 'rgba(255, 255, 255, 0.05)'}
                              h={['44px']}
                              _hover={{
                                color: 'white',
                                bg: !nft?.has_logged_in_user_voted ? '#43BDD7' : 'rgba(255, 255, 255, 0.20)',
                              }}
                              fontFamily=" Montserrat"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight="600"
                              lineHeight="normal"
                              onClick={() => onVoteNowClick(nft)}
                              leftIcon={<VotesIcon color={'#FFFFFF'} />}
                            >
                              {nft?.has_logged_in_user_voted ? 'Unvote' : 'Vote'}
                            </Button>
                          </Box>
                        </>
                      )}
                    {!showButton && (
                      <>
                        <Box marginTop="10px">
                          <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="18px" />
                          <Button
                            width="100%"
                            borderRadius="15px"
                            borderTop="1px solid #04D3FF"
                            padding="16px 32px"
                            color=" #FFF"
                            backgroundColor="#118BCF"
                            h={['44px']}
                            _hover={{
                              color: 'white',
                              bg: '#43BDD7',
                            }}
                            fontFamily=" Montserrat"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="600"
                            lineHeight="normal"
                            onClick={() => onSelectClick(nft)}
                          >
                            Select
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
          </Box>
        </>
      )}
    </>
  );
}
